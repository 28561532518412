import { PaletteOptions, Components } from '@mui/material';

import merge from 'lodash/merge';
import Button from './components/Button';
import IconButton from './components/IconButton';
import LinearProgress from './components/LinearProgress';
import Input from './components/Input';
import Pagination from './components/Pagination';
import PaginationItem from './components/PaginationItem';
import Checkbox from './components/Checkbox';
// import Fab from './components/Fab';
// import Card from './components/Card';
// import Chip from './components/Chip';
// import Tabs from './components/Tabs';
// import Menu from './components/Menu';
// import Grid from './components/Grid';
// import Link from './components/Link';
// import Lists from './components/Lists';
// import Table from './components/Table';
// import Alert from './components/Alert';
// import Badge from './components/Badge';
// import Paper from './components/Paper';
// import Radio from './components/Radio';
// import Drawer from './components/Drawer';
// import Dialog from './components/Dialog';
// import Avatar from './components/Avatar';
// import Rating from './components/Rating';
// import Slider from './components/Slider';
// import Switch from './components/Switch';
// import Select from './components/Select';
// import SvgIcon from './components/SvgIcon';
// import Tooltip from './components/Tooltip';
// import Popover from './components/Popover';
// import Stepper from './components/Stepper';
// import Pickers from './components/Pickers';
// import DataGrid from './components/DataGrid';
// import Skeleton from './components/Skeleton';
// import Backdrop from './components/Backdrop';
// import Snackbar from './components/Snackbar';
// import Progress from './components/Progress';
// import Timeline from './components/Timeline';
// import TreeView from './components/TreeView';
// import Container from './components/Container';
// import Accordion from './components/Accordion';
// import Typography from './components/Typography';
// import Breadcrumbs from './components/Breadcrumbs';
// import ButtonGroup from './components/ButtonGroup';
// import FormHelperText from './components/FormHelperText';
// import Autocomplete from './components/Autocomplete';
// import ToggleButton from './components/ToggleButton';
// import ControlLabel from './components/ControlLabel';

// ----------------------------------------------------------------------

export default function createComponents(palette: PaletteOptions): Components {
  return merge(
    LinearProgress(palette),
    Input(palette),
    Pagination(palette),
    PaginationItem(palette),
    Checkbox(palette),
    IconButton(palette),
    ...Button(palette)
    // Fab(palette),
    // Tabs(palette),
    // Chip(palette),
    // Card(palette),
    // Menu(palette),
    // Grid(palette),
    // Link(palette),
    // Radio(palette),
    // Badge(palette),
    // Lists(palette),
    // Table(palette),
    // Paper(palette),
    // Alert(palette),
    // Switch(palette),
    // Select(palette),
    // Rating(palette),
    // Dialog(palette),
    // Avatar(palette),
    // Slider(palette),
    // Drawer(palette),
    // Pickers(palette),
    // Stepper(palette),
    // Tooltip(palette),
    // Popover(palette),
    // SvgIcon(palette),
    // DataGrid(palette),
    // Skeleton(palette),
    // Timeline(palette),
    // TreeView(palette),
    // Backdrop(palette),
    // Snackbar(palette),
    // Progress(palette),
    // Container(palette),
    // Accordion(palette),
    // IconButton(palette),
    // Typography(palette),
    // ButtonGroup(palette),
    // Breadcrumbs(palette),
    // Autocomplete(palette),
    // ControlLabel(palette),
    // FormHelperText(palette),
    // ToggleButton(palette)
  );
}
