import { removeSnackbar } from 'store/slices/notifier';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useAppSelector, useAppDispatch } from 'store';

const useNotifier = () => {
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector(state => state.notifier);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    notifications.forEach(({ key, message, type, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      enqueueSnackbar(message, {
        key,
        variant: type,
        preventDuplicate: true,
        onExited: (_, notifierkey) => {
          dispatch(removeSnackbar(notifierkey));
        },
      });
    });
  }, [notifications, enqueueSnackbar, closeSnackbar, dispatch]);

  return null;
};

export default useNotifier;
