import withError from '../queryLifecycle/withError';
import { dropskinApi } from '../service';

export interface PromoCodes {
  [key: string]: any;
}

export interface PromoCase {
  [key: string]: any;
}
export interface UserPromos {
  active: any;
  cases: any;
  codes: any;
}

export const promosAPi = dropskinApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserPromoCodesHistory: builder.query({
      query: () => ({
        url: '/promo-code/history',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['promoCodeHistory']
      //transformResponse: (response: any) => (response.length ? response : [])
    }),
    getPromoCaseHistory: builder.query<PromoCase, void>({
      query: () => ({
        url: '/promo-auth/user/free-cases/history',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['promoCaseHistory']
    }),
    getActivatedPromoCode: builder.query<PromoCodes, any>({
      query: () => ({
        url: '/promo-code/active-promo',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['promoActive']
    }),
    activatePromoCode: builder.mutation<PromoCodes[], { code: string; captcha: string }>({
      query: (body) => ({
        url: `/promo-code/activate`,
        method: 'post',
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['promoActive', 'promoCodeHistory', 'allUserPromo']
    }),
    activatePromoCase: builder.mutation<PromoCodes[], { code: string; captcha: string }>({
      query: (body) => ({
        url: `/promo-auth/activate`,
        method: 'post',
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['promoCaseHistory', 'accountGifts']
    }),
    isPromoCaseUsed: builder.query<boolean, void>({
      query: () => ({
        url: '/promo-auth/user/:caseId',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    promoActivateHistory: builder.query<PromoCase[], void>({
      query: () => ({
        url: '/promo-auth/history',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['promoCaseHistory'],
      transformResponse: (response: any) => (response.length ? response : [])
    }),
    getUserPromo: builder.query<UserPromos, void>({
      query: () => ({
        url: '/promo-code/list',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['allUserPromo']
      // transformResponse: (response: any) => (response.length ? response : [])
    })
  })
});

export const {
  useGetActivatedPromoCodeQuery,
  useActivatePromoCaseMutation,
  useGetUserPromoCodesHistoryQuery,
  useGetPromoCaseHistoryQuery,
  useIsPromoCaseUsedQuery,
  usePromoActivateHistoryQuery,
  useActivatePromoCodeMutation,
  useGetUserPromoQuery
} = promosAPi;
