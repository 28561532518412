import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  inversSide: false,
  leverage: 2,
  inventoryItems: [],
  upgraderItem: null,
  chance: 0,
  minPrice: 0,
  maxPrice: 999_999_999,
  search: '',
  isPlaying: false
};

export const upgraderReducer = createSlice({
  name: '@upgrader',
  initialState: INITIAL_STATE,
  reducers: {
    toggleSide(state) {
      state.inversSide = !state.inversSide;
    },
    setIsPlaying(state, { payload }) {
      state.isPlaying = payload;
    },
    setLeverage(state, { payload }) {
      state.leverage = payload;
    },
    setInventoryItem(state, { payload }) {
      state.inventoryItems = payload;
    },
    addInventoryItem(state, { payload }) {
      state.inventoryItems.push(payload);
    },
    removeInventoryItem(state, { payload }) {
      state.inventoryItems = state.inventoryItems.filter((item) => item.id !== payload);
    },
    setUpgraderItem(state, { payload }) {
      state.upgraderItem = payload;
    },
    resetGame(state) {
      state.inventoryItems = INITIAL_STATE.inventoryItems;
      state.upgraderItem = INITIAL_STATE.upgraderItem;
      state.chance = INITIAL_STATE.chance;
      state.isPlaying = INITIAL_STATE.isPlaying;
    }
  }
});

export const {
  toggleSide,
  setIsPlaying,
  setLeverage,
  setInventoryItem,
  addInventoryItem,
  removeInventoryItem,
  setUpgraderItem,
  resetGame
} = upgraderReducer.actions;

export default upgraderReducer.reducer;
