import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import type { VariantType, SnackbarKey } from 'notistack';

export interface NotificationKey {
  payload: SnackbarKey;
}

export interface Notification {
  message: string;
  type: VariantType;
  key: SnackbarKey;
  dismissed: boolean;
}

export interface NotificationPayload {
  payload: {
    message: string;
    type: VariantType;
  };
}

export interface NotificationsState {
  notifications: Notification[];
}

const INITIAL_STATE: NotificationsState = {
  notifications: [], // contains the list of notifications
};

export const notifierReducer = createSlice({
  name: '@notifier',
  initialState: INITIAL_STATE,
  reducers: {
    notify(state, { payload }: NotificationPayload) {
      const notification: Notification = {
        message: payload.message,
        type: payload.type,
        dismissed: false,
        key: (new Date().getTime() + Math.random()).toString(),
      };
      state.notifications.push(notification);
    },
    closeSnackbar(state, { payload }: NotificationKey) {
      state.notifications = state.notifications.map(notification =>
        notification.key === payload
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeSnackbar(state, { payload }: NotificationKey) {
      state.notifications = state.notifications.filter(
        ({ key }) => key !== payload
      );
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      state.notifications = action.payload.notifier.notifications;
    },
  },
});

export const { notify, closeSnackbar, removeSnackbar } =
  notifierReducer.actions;

export default notifierReducer.reducer;
