import { SnackbarProvider, useSnackbar } from 'notistack';
import { ChildrenProp } from '../models/types/children-prop';

const NotificationProvider = ({ children }: ChildrenProp) => {
  return (
    <SnackbarProvider
      hideIconVariant
      preventDuplicate
      action={(key) => <NotificationCloseButton snackbarKey={key} />}
      autoHideDuration={10000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      maxSnack={3}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotificationProvider;

// TODO: to separate this components
//-----------CloseButton

import type { SnackbarKey } from 'notistack';

const NotificationCloseButton = ({ snackbarKey }: { snackbarKey: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const handleClick = () => {
    closeSnackbar(snackbarKey);
  };

  return <CloseButton onClick={handleClick} size='small' />;
};

//-----------CloseButton

import IconButton from '@mui/material/IconButton';
import CloseIcon from 'oldAssets/new/MarketClose.svg';
import React from 'react';

type CloseButtonProps = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: 'small' | 'medium' | 'large';
};

const CloseButton = ({ onClick = () => {}, size = 'small' }: CloseButtonProps) => {
  return (
    <IconButton aria-label='delete' onClick={onClick} size={size}>
      <CloseIcon />
    </IconButton>
  );
};
