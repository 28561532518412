import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DiamondIcon from 'oldAssets/new/IconDiamant.svg';

import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  gap: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[800],
  border: `1px solid ${theme.palette.greyTransparency[700]}`,
  // borderImage: 'linear-gradient(180deg, #23232E 0%, rgba(36, 36, 45, 0) 100%)',
  borderRadius: theme.spacing(1),
  marginRight: theme.spacing(0.5)
}));

const DiamondPaper: React.FC = () => {
  return (
    <Box zIndex={2}>
      <StyledPaper
        elevation={0}
        sx={{
          height: {
            phxs: 90,
            phlg: 100
          },
          width: {
            phxs: 90,
            phlg: 100
          }
        }}
      >
        <DiamondIcon />
        <Typography variant='body1' color={(theme) => theme.palette.primaries[400]}>
          Last Drop
        </Typography>
      </StyledPaper>
    </Box>
  );
};

export default DiamondPaper;
