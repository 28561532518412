import {
  Dialog,
  useTheme,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip
} from '@mui/material';

import React from 'react';
import Image from 'next/image';

import { useAppSelector } from 'store';

import { useGetUserPromoQuery } from 'store/rtk/promos/service';

import CloseIcon from 'assets/new/MarketClose.svg';
import PromoIcon from 'assets/promocode/PromoIcon.webp';
import GiftIcon from 'assets/notifications/types/Gift.svg';
import LogInButton from 'components/LogInButton';
import moment from 'moment';

import InfoIcon from 'assets/notifications/types/Info.svg';
import isNumber from 'lodash/isNumber';
import { usePromocodeModalContext } from 'context/modals/PromocodeModalProvider/PromocodeModalProvider';

const PromoModal = ({ modalState, hideModal }) => {
  const theme = useTheme();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  return (
    <Dialog
      key={'promocode-dialog'}
      open={modalState}
      onClose={hideModal}
      PaperProps={{
        sx: {
          maxHeight: { phxs: '90vh', phlg: 600 },
          minHeight: 420,
          borderRadius: 4,
          overflow: 'auto',
          background: theme.palette.grey[800],
          color: theme.palette.white,
          width: 512,
          p: {
            phxs: 2,
            tbxs: 3
          },
          gap: 2
        }
      }}
    >
      <>
        <Box display='flex' gap={2} width={1} flexDirection='column'>
          {/* TOP TEXT */}
          <Box display='flex' gap={1} flexDirection='column' alignItems='center'>
            <Box width={64} height={64} position='relative'>
              <Image unoptimized alt={'promo-icon'} src={PromoIcon} quality={100} />
            </Box>
            <Box display='flex' gap={0.5} flexDirection='column' textAlign='center'>
              <Typography variant='h5' color={theme.palette.secondaries[400]}>
                Use promo code & get the bonus
              </Typography>
              <Typography variant='body1' color={theme.palette.grey[400]}>
                Paste the promo code below and click the Get Bonus button
              </Typography>
            </Box>
          </Box>

          {/* INPUT & BUTTON */}
          <PromoCodeActions />

          <IconButton
            onClick={hideModal}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              m: 0.5,
              path: { fill: theme.palette.greyTransparency[100] }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          display='flex'
          gap={1}
          flex={1}
          width={1}
          height={1}
          flexDirection='column'
          alignItems='center'
          overflow='auto'
        >
          <Typography variant='subtitle1'>History of codes</Typography>
          {isAuthenticated ? <TPromoTable /> : <NotAuthPromoTable />}
        </Box>
      </>
    </Dialog>
  );
};

export default PromoModal;

// --------------------------------------------------------

const PromoCodeActions = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const { promoState, setPromoState, handleApplyPromo } = usePromocodeModalContext();

  const onPromoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value || '';
    setPromoState(text.trim());
  };

  return (
    <Box display='flex' gap={1} flexDirection={{ phxs: 'column', phlg: 'row' }} width={1}>
      <TextField
        value={promoState || ''}
        disabled={!isAuthenticated}
        onChange={onPromoChange}
        type='text'
        variant='outlined'
        inputProps={{
          sx: { px: 1 },
          width: '100%'
        }}
        placeholder={'Enter promo code'}
        // disabled={!isAuthenticated}
        sx={{
          width: '100%',
          height: 48,

          '& .MuiInputBase-root input': {
            textAlign: 'center'
          }
        }}
      />
      <Button
        disabled={!isAuthenticated || promoState.length === 0}
        variant='contained'
        color='primary'
        startIcon={<GiftIcon />}
        sx={{ minWidth: 148 }}
        onClick={handleApplyPromo}
      >
        Get bonus
      </Button>
    </Box>
  );
};

const NotAuthPromoTable = () => {
  return (
    <Box
      display='flex'
      gap={2}
      flex={1}
      width={1}
      height={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Typography variant='subtitle1' color={(theme) => theme.palette.grey[400]}>
        You should log in firstly
      </Typography>
      <LogInButton />
    </Box>
  );
};

const TPromoTable = () => {
  const { data } = useGetUserPromoQuery();

  if (data?.length <= 0) {
    return <EmptyPromoTable />;
  }

  return (
    <Box display='flex' gap={1} width={1} height={1} flexDirection='column' overflow='auto'>
      {/* TABLE HEADER */}
      <Box display={{ phxs: 'none', phlg: 'flex' }} width={1} px={(theme) => theme.spacing(2)}>
        <Typography width={1 / 3} variant='body2' color={(theme) => theme.palette.grey[400]}>
          Promo code
        </Typography>
        <Typography width={1 / 3} variant='body2' color={(theme) => theme.palette.grey[400]}>
          Bonus amount
        </Typography>
        <Typography width={1 / 3} variant='body2' color={(theme) => theme.palette.grey[400]}>
          Date of use
        </Typography>
      </Box>

      <Box display='flex' gap={1} width={1} height={1} flexDirection='column' overflow='auto'>
        {data?.active && (
          <PromoItem
            isActive
            promoCode={data?.active?.promoCode?.code}
            bonus={data?.active?.promoCode?.depositPercent}
            date={data?.active?.promoCode?.updatedAt}
            bonusAmount={data?.active?.promoCode?.bonusAmount}
            isAmountUsed={data?.active?.moneyUsed}
          />
        )}

        {data?.cases?.map((item, i) => (
          <PromoItem
            isCase
            key={`promo-case-item-${i}`}
            promoCode={item?.promoCaseCode}
            bonus={item?.case?.price}
            date={item?.updatedAt}
          />
        ))}

        {data?.codes?.map((item, i) => (
          <PromoItem
            key={`promo-code-item-${i}`}
            promoCode={item?.promoCode?.code}
            bonus={item?.promoCode?.depositPercent}
            date={item?.updatedAt}
            bonusAmount={item?.promoCode?.bonusAmount}
            isAmountUsed={item?.moneyUsed}
          />
        ))}
      </Box>
    </Box>
  );
};

const PromoItem = ({
  isActive = false,
  isCase = false,
  promoCode = '',
  bonus = '',
  amount = 0,
  percentage = 0,
  date = '',
  bonusAmount = 0,
  isAmountUsed = false
}) => {
  const theme = useTheme();

  const textColor = isActive ? theme.palette.secondaries[400] : theme.palette.grey[500];

  return (
    <Box
      display='flex'
      gap={0.5}
      p={{ phxs: 1, phlg: theme.spacing(1.5, 2) }}
      borderRadius={theme.spacing(1)}
      bgcolor={theme.palette.grey[700]}
    >
      {/* MOBILE ONLY */}
      <Box
        display={{ phxs: 'flex', phlg: 'none' }}
        flexDirection='column'
        width={1 / 2}
        height={1}
        textAlign='end'
        gap={0.5}
      >
        <Typography
          variant='body1'
          color={(theme) => theme.palette.grey[400]}
          fontSize={{ phxs: '14px !important', tbxs: '16px !important' }}
        >
          Promo code
        </Typography>
        <Typography
          variant='body1'
          color={(theme) => theme.palette.grey[400]}
          fontSize={{ phxs: '14px !important', tbxs: '16px !important' }}
        >
          Bonus amount
        </Typography>
        <Typography
          variant='body1'
          color={(theme) => theme.palette.grey[400]}
          fontSize={{ phxs: '14px !important', tbxs: '16px !important' }}
        >
          Date of use
        </Typography>
      </Box>

      <Box
        display='flex'
        flexDirection={{ phxs: 'column', phlg: 'row' }}
        width={{ phxs: 1 / 2, phlg: 1 }}
        height={1}
        gap={0.5}
      >
        <Typography
          width={{ phxs: 1, phlg: 1 / 3 }}
          variant='body1'
          color={textColor}
          fontSize={{ phxs: '14px !important', tbxs: '16px !important' }}
          overflow='hidden'
          whiteSpace='nowrap'
          textOverflow='ellipsis'
        >
          {promoCode}
        </Typography>
        <Box width={{ phxs: 1, phlg: 1 / 3 }} display='flex' gap={0.5}>
          <Typography
            variant='body1'
            color={textColor}
            fontSize={{ phxs: '14px !important', tbxs: '16px !important' }}
            sx={{
              span: {
                textDecoration: isAmountUsed ? 'line-through' : ''
              }
            }}
          >
            {/* {isNumber(percentage) && `${percentage}%`} */}
            {/* {isNumber(amount) && amount > 0 && `$${amount}`} */}
            {isCase ? `$${bonus}` : `${bonus}%`}{' '}
            {isNumber(bonusAmount) && bonusAmount > 0 && <span>${bonusAmount}</span>}
          </Typography>
          {isActive && isAmountUsed && <InfoTooltip />}
        </Box>
        <Typography
          width={{ phxs: 1, phlg: 1 / 3 }}
          variant='body1'
          color={textColor}
          fontSize={{ phxs: '14px !important', tbxs: '16px !important' }}
        >
          {moment(date).format('DD.MM.YYYY HH:mm').toString()}
        </Typography>
      </Box>
    </Box>
  );
};

// --------------- DONE ---------------

const InfoTooltip = ({ text = 'Bonus money was already taken in another promocode' }) => {
  return (
    <Tooltip title={text} placement='top'>
      <IconButton
        sx={{
          p: 0,
          svg: { width: 16, height: 16 },
          path: { fill: (theme) => theme.palette.grey[400] }
        }}
      >
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

const EmptyPromoTable = () => {
  return (
    <Box
      display='flex'
      gap={2}
      flex={1}
      width={1}
      height={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Typography variant='subtitle1' color={(theme) => theme.palette.grey[400]}>
        You don't have any promocode redeemed
      </Typography>
      {/* <Typography
        variant='body1'
        sx={{
          span: { color: (theme) => theme.palette.secondaries[400] }
        }}
      >
        Use promo code <span>DROPSKIN</span> for +10% balance refill
      </Typography> */}
    </Box>
  );
};
