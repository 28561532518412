import { alpha, PaletteOptions } from '@mui/material';

const MuiButton = (palette: PaletteOptions) => {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: alpha(palette.secondaries[800], 0.3),

        },
        barColorSecondary: {
          backgroundColor: palette.secondaries[800],
          borderRadius: 5,
        },
      },
      variants: [
        {
          props: { variant: 'determinate', color: 'secondary' },
          style: {
            height: 8,
            borderRadius: 5,
          },
        },
      ],
    },
  }
};

export default MuiButton;
