import withError from '../queryLifecycle/withError';
import { dropskinApi } from '../service';

import type {
  GiveawayProgressResponse,
  GiveawayType,
  GiveawaysByIdResponse,
  GiveawaysByRarityRequest,
  GiveawaysListResponse,
  GiveawaysRequest,
  GiveawaysResponse
} from 'store/rtk/userGiveaways/types';

export const userGiveawaysApi = dropskinApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserGiveaways: builder.query<GiveawaysResponse, GiveawaysRequest>({
      query: ({ finished, take }) => ({
        url: `/user-giveaway/`,
        params: { finished, take },
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0
    }),
    getUserGiveawaysByRarity: builder.query<GiveawaysListResponse, GiveawaysByRarityRequest>({
      query: ({ finished, status, rarity, take }) => ({
        url: `/user-giveaway/rarity`,
        params: { finished, status, rarity, take },
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0
    }),
    getUserGiveawaysStats: builder.query<{ totalPrice: string; totalGiveaways: number }, void>({
      query: () => ({
        url: `/user-giveaway/stats`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0
    }),
    getUserGiveawaysById: builder.query<GiveawaysByIdResponse, number>({
      query: (giveawayId) => ({
        url: `/user-giveaway/${giveawayId}`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0,
      providesTags: ['ugw-id']
    }),
    // ----------------- AUTH -----------------
    // getUserGiveawaysByIdAuth: builder.query<GiveawaysByIdResponse, number>({
    //   query: (giveawayId) => ({
    //     url: `/user-giveaway-auth/giveaway/${giveawayId}`,
    //     method: 'get'
    //   }),
    //   async onQueryStarted(_, { dispatch, queryFulfilled }) {
    //     await withError({ dispatch, queryFulfilled });
    //   },
    //   keepUnusedDataFor: 0,
    //   providesTags: ['ugw-id']
    // }),
    getUserGiveawaysByUser: builder.query<GiveawaysListResponse, void>({
      query: () => ({
        url: `/user-giveaway-auth/my`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0
    }),
    getUserGiveawaysByUserParticipated: builder.query<GiveawaysListResponse, void>({
      query: () => ({
        url: `/user-giveaway-auth/my/participating`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0
    }),
    getUserGiveawayProgress: builder.query<GiveawayProgressResponse, number>({
      query: (giveawayId) => ({
        url: `/user-giveaway-auth/progress/${giveawayId}`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0,
      providesTags: ['ugw-progress']
    }),
    startUserGiveaway: builder.mutation<void, number>({
      query: (giveawayId) => ({
        url: `/user-giveaway-auth/start/${giveawayId}`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['ugw-id', 'ugw-progress']
    }),
    joinUserGiveaway: builder.mutation<boolean, number>({
      query: (giveawayId) => ({
        url: `/user-giveaway-auth/join/${giveawayId}`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
      // invalidatesTags: ['ugw-id', 'ugw-progress']
    }),
    createUserGiveaway: builder.mutation<GiveawayType, any>({
      query: (body) => ({
        url: `/user-giveaway-auth/create`,
        method: 'post',
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['balance']
    }),
    cancelUserGiveaway: builder.mutation<void, number>({
      query: (giveawayId) => ({
        url: `/user-giveaway-auth/cancel/${giveawayId}`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['ugw-id', 'balance']
    })
  })
});

export const {
  useGetUserGiveawaysQuery,
  useGetUserGiveawaysByRarityQuery,
  useGetUserGiveawaysStatsQuery,
  useGetUserGiveawaysByIdQuery,
  // useGetUserGiveawaysByIdAuthQuery,
  useGetUserGiveawaysByUserQuery,
  useGetUserGiveawaysByUserParticipatedQuery,
  useGetUserGiveawayProgressQuery,
  useStartUserGiveawayMutation,
  useJoinUserGiveawayMutation,
  useCreateUserGiveawayMutation,
  useCancelUserGiveawayMutation
} = userGiveawaysApi;
