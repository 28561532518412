import React from 'react';
import Box from '@mui/material/Box';
import SocialLinks from './SocialLinks';
import SiteLinks from './SiteLinks';

const LinksBar: React.FC = () => {
  return (
    <Box display='flex' justifyContent='space-between' pt={1} width={1} zIndex={1}>
      <SocialLinks />
      <SiteLinks />
    </Box>
  );
};

export default LinksBar;
