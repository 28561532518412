import React from 'react';
import Box from '@mui/material/Box';

import GlobalStats from './GlobalStats';
import FooterDetails from './FooterDetails';
import FooterNav from './FooterNav';
import FooterPay from './FooterPay';
import SiteContainer from 'components/SiteContainer';

// import FooterImage from 'oldAssets/footer/footer-image.png';
import FooterImage from 'oldAssets/footer/footer-image.webp';

const Footer: React.FC = () => {
  return (
    <Box
      zIndex={1}
      mt='auto'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        py: 4,
        backgroundImage: `url(${FooterImage?.src})`, // 'url("assets/footer/footer-image.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center'
      }}
    >
      <SiteContainer>
        <Box display='flex' flexDirection='column' alignItems='center' gap={4} width={1}>
          <GlobalStats />
          <FooterDetails />
          <FooterNav />
          <FooterPay />
        </Box>
      </SiteContainer>
    </Box>
  );
};

export default React.memo(Footer);
