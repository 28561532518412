import { useAppDispatch } from 'store';
import { notify } from 'store/slices/notifier';

export const formatCaseName = (name: string) => name?.trim().replace(/-/g, ' ');

export const checkUserName = (username: string) => {
  const dispatch = useAppDispatch();

  if (username.search(/DROP.SKIN/i) !== -1) {
    dispatch(
      notify({
        message: 'Open Your Daily Case',
        type: 'success'
      })
    );
    return true;
  }

  dispatch(notify({ message: 'Check Condition Below', type: 'warning' }));
  return false;
};

export const lowerCaseName = (name: string) => {
  return name?.trim()?.toLowerCase();
};
