import { Dispatch } from 'redux';
import { setAuth } from 'store/slices/auth';
import { removeCookies } from 'cookies-next';

interface CheckUserBanProps {
  dispatch: Dispatch;
  queryFulfilled: Promise<any>;
}

async function checkUserBan({ dispatch, queryFulfilled }: CheckUserBanProps) {
  const { data } = await queryFulfilled;
  const { isBanned = false } = data;
  if (isBanned) {
    dispatch(setAuth({ isAuthenticated: false, isLoading: false, token: '' }));
    removeCookies('accessToken');
  }
}

export default checkUserBan;
