import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import StatInfo from './StatInfo';
import { useAppDispatch, useAppSelector } from 'store';

import UsersIcon from 'oldAssets/drop/preUser.svg';

import CasesIcon from 'oldAssets/header/Cases.svg';
import UpgradeIcon from 'oldAssets/header/Upgrade.svg';
import BattleIcon from 'oldAssets/header/Battle.svg';

import { io } from 'socket.io-client';
import { SOCKET_API_URL } from '@config';

import { GlobalGamesCount } from 'models/types/stats-socket';
import { setGlobalCount } from 'store/slices/stats/globalCount';

const GLOBAL_GAMES_COUNT = 'global_games_count';

const statsSocket = io(`${SOCKET_API_URL}/stats`, {
  transports: ['websocket', 'pooling']
});

const GlobalStats: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    globalCount: { cases, upgrader, users, battle }
  } = useAppSelector((state) => state.globalCount);

  useEffect(() => {
    statsSocket.emit(GLOBAL_GAMES_COUNT, (data: GlobalGamesCount) => {
      dispatch(setGlobalCount(data));
    });

    statsSocket.on(GLOBAL_GAMES_COUNT, (data: GlobalGamesCount) => {
      dispatch(setGlobalCount(data));
    });

    return () => {
      statsSocket.close();
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap'
      }}
    >
      <StatInfo
        image={<CasesIcon />}
        count={cases}
        text={'Opened Cases'}
        color={theme.palette.drop.orange}
      />
      <StatInfo
        image={<UpgradeIcon />}
        count={upgrader}
        text={'Upgrade'}
        color={theme.palette.drop.green}
      />
      <StatInfo image={<UsersIcon />} count={users} text={'Users'} color={theme.palette.drop.red} />
      <StatInfo
        image={<BattleIcon />}
        count={battle}
        text={'Battles'}
        color={theme.palette.drop.yellow}
      />
    </Box>
  );
};

export default GlobalStats;
