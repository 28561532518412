import { ENV, NEXT_PUBLIC_GOOGLE_TAG_MANAGER } from '@config';

const InternalScripts = () => {
  if (ENV === 'production')
    return (
      <>
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${NEXT_PUBLIC_GOOGLE_TAG_MANAGER}`}
            height='0'
            width='0'
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      </>
    );

  return null;
};

export default InternalScripts;
