import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  items: [],
  winItem: null,
  isPlayed: false,
  isScrolling: false
};

export const freeCasesReducer = createSlice({
  name: '@freeCases',
  initialState: INITIAL_STATE,
  reducers: {
    setItems(state, { payload }) {
      state.items = payload;
    },
    addItem(state, { payload }) {
      state.items.push(payload);
      const randomEnd = state.items.slice(2, 6);
      state.items = state.items.concat(randomEnd);
    },
    setWinItem(state, { payload }) {
      state.winItem = payload;
      state.isPlayed = true;
    },
    setIsPlayed(state, { payload }) {
      state.isPlayed = payload;
    },
    setIsScrolling(state, { payload }) {
      state.isScrolling = payload;
    }
  }
});

export const {
  setItems,
  addItem,
  setWinItem,
  setIsPlayed,
  setIsScrolling
} = freeCasesReducer.actions;

export default freeCasesReducer.reducer;
