import { combineReducers } from 'redux';
import { dropskinApi } from './rtk/service';
import notifier from './slices/notifier';
import auth from './slices/auth';
import lastDrops from './slices/stats/lastDrops';
import globalCount from './slices/stats/globalCount';
import chat from './slices/chat';
import upgrader from './slices/games/upgrader';
import freeCases from './slices/games/freeCases';
import caseRoulette from './slices/games/caseRoulette';
import settings from './slices/settings';
import popups from './slices/popups';

const rootReducers = combineReducers({
  [dropskinApi.reducerPath]: dropskinApi.reducer,
  notifier,
  auth,
  lastDrops,
  globalCount,
  chat,
  upgrader,
  freeCases,
  caseRoulette,
  settings,
  popups
});

export default rootReducers;
