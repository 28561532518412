import React from 'react';
import { useAppDispatch } from 'store';
import { setAuth } from 'store/slices/auth';
import { deleteCookie } from 'cookies-next';

const useUser = () => {
  const dispatch = useAppDispatch();

  const checkUser = async () => {
    const request = await fetch('/api/auth');
    const { isAuthenticated, token } = await request.json();
    dispatch(setAuth({ isAuthenticated, token }));
  };

  const signOut = React.useCallback(async () => {
    deleteCookie('accessToken');
    dispatch(setAuth({ isAuthenticated: false, token: '' }));
  }, []);

  return { checkUser, signOut };
};

export default useUser;
