import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { GlobalGamesCount } from 'models/types/stats-socket';

export type GlobalCountPayload = {
  payload: GlobalGamesCount;
};

export type GlobalCountState = {
  globalCount: GlobalGamesCount;
};

export const INITIAL_STATE: GlobalCountState = {
  globalCount: {
    battle: 0,
    cases: 0,
    crash: 0,
    upgrader: 0,
    users: 0
  }
};

export const globalCountReducer = createSlice({
  name: '@globalCount',
  initialState: INITIAL_STATE,
  reducers: {
    setGlobalCount(state, { payload }: GlobalCountPayload) {
      state.globalCount = payload;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (action.payload.lastDrops.lastDrop) {
        return state;
      }
      state.globalCount = action.payload.globalCount.globalCount;
    }
  }
});

export const { setGlobalCount } = globalCountReducer.actions;

export default globalCountReducer.reducer;
