import { Box } from '@mui/material';
import React from 'react';
import { FOOTER_IMAGES } from 'constants/footer-links';

const FooterPay: React.FC = () => {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-evenly' flexWrap='wrap' gap={3}>
      {FOOTER_IMAGES.map(({ icon, text }) => (
        <Box key={text + '-footer-pay'} display='flex'>
          {icon}
        </Box>
      ))}
    </Box>
  );
};

export default FooterPay;
