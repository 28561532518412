import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from 'next/link';

import AddIcon from 'oldAssets/actions/Add.svg';
import { useGetActivatedPromoCodeQuery } from 'store/rtk/promos/service';
import { useGetReferalCodeQuery } from 'store/rtk/deposit/service';

const FundsButton = ({ hideText = false, fullWidth = false, ...props }) => {
  const { data: referal } = useGetReferalCodeQuery();
  const { data: promoCodesActive } = useGetActivatedPromoCodeQuery(null);

  const sumPercent = promoCodesActive?.activePercentage + referal?.referral || 0;

  return (
    <Box display='flex' gap={1} justifyContent='center' alignItems='center' {...props}>
      <Link shallow href={'/deposit'} passHref style={{ width: '100%' }}>
        {hideText ? (
          <IconButton
            sx={(theme) => ({
              height: 48,
              width: 48,
              p: 0,
              border: `2px solid ${theme.palette.secondaries[400]}`,
              '&:hover': {
                border: `2px solid ${theme.palette.secondaries[800]}`,
                backgroundColor: theme.palette.secondaries[800]
              }
            })}
          >
            <AddIcon />
          </IconButton>
        ) : (
          <Button fullWidth variant='outlined' color='secondary' startIcon={<AddIcon />}>
            Add Funds
          </Button>
        )}
      </Link>
      <Typography variant='body1' color={(theme) => theme.palette.secondaries[400]}>
        +{sumPercent}%
      </Typography>
    </Box>
  );
};

export default FundsButton;
