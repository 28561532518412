import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Breakpoint, PaletteOptions, SimplePaletteColorOptions } from '@mui/material';

import { breakpoints } from './breakpoints';
import { IBreakpoints } from './constants/breakpoints';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    paragraph: React.CSSProperties;
    subtitle3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    paragraph?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    paragraph: true;
    subtitle3: true;
  }
}

type OptionalBreakpoints = Partial<IBreakpoints>;

const htmlFontSize = 16;
const fontSize = 14;
const coef: number = fontSize / 14;
export const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem !important`;

const responsiveFontSizes = (breakpointValues: OptionalBreakpoints) => {
  const breakpointStyles = {};

  Object.entries<number>(breakpointValues).forEach(([key, value]) => {
    Object.assign(breakpointStyles, {
      [breakpoints.only(key as Breakpoint)]: {
        fontSize: pxToRem(value)
      }
    });
  });

  return breakpointStyles;
};

const createTypography = (palette: PaletteOptions): TypographyOptions => ({
  htmlFontSize,
  fontSize,
  fontFamily: 'Kanit',
  h1: {
    color: palette.white,
    fontSize: pxToRem(64),
    fontWeight: 500,
    lineHeight: 1,
    // letterSpacing: -2,
    ...responsiveFontSizes({
      // tb: 64
    })
  },
  h2: {
    color: palette.white,
    fontSize: pxToRem(48),
    fontWeight: 500,
    lineHeight: 1,
    ...responsiveFontSizes({
      // tb: 48
    })
  },
  h3: {
    color: palette.white,
    fontSize: pxToRem(40),
    // letterSpacing: 0,
    lineHeight: 1,
    fontWeight: 500,
    ...responsiveFontSizes({
      // tb: 40
    })
  },
  h4: {
    color: palette.white,
    fontSize: pxToRem(32),
    lineHeight: 1.2,
    fontWeight: 500,
    ...responsiveFontSizes({
      tblg: 32
    })
  },
  h5: {
    color: palette.white,
    fontSize: pxToRem(28),
    lineHeight: 1.2,
    fontWeight: 500,
    ...responsiveFontSizes({
      // tb: 28
    })
  },
  h6: {
    color: palette.white,
    fontSize: pxToRem(24),
    lineHeight: 1.2,
    fontWeight: 500,
    ...responsiveFontSizes({
      // tb: 24
    })
  },
  subtitle1: {
    color: palette.white,
    fontSize: pxToRem(20),
    // letterSpacing: 4,
    lineHeight: 1.4,
    fontWeight: 400,
    ...responsiveFontSizes({
      // tb: 20
    })
  },
  subtitle2: {
    color: palette.white,
    fontSize: pxToRem(18),
    // letterSpacing: 1,
    lineHeight: 1.4,
    fontWeight: 400,
    ...responsiveFontSizes({
      // tb: 18
    })
  },
  //-------------------- delete this
  subtitle3: {
    color: palette.white,
    fontSize: pxToRem(20),
    // letterSpacing: 1,
    lineHeight: 1.4,
    fontWeight: 400,
    ...responsiveFontSizes({
      // tb: 12
    })
  },
  //--------------------
  body1: {
    color: palette.white,
    fontSize: pxToRem(16),
    lineHeight: 1.4,
    fontWeight: 400
  },
  body2: {
    color: palette.white,
    fontSize: pxToRem(14),
    lineHeight: 1.4,
    fontWeight: 400
  },
  button: {
    color: palette.white,
    fontSize: pxToRem(16),
    lineHeight: '24px',
    fontWeight: 500
  },
  caption: {
    color: palette.white,
    fontSize: pxToRem(12),
    lineHeight: 1.1,
    fontWeight: 400,
    ...responsiveFontSizes({
      // tb: 14
    })
  },
  overline: {
    color: palette.white,
    fontSize: pxToRem(10),
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: '0.33px'
  }
});

export default createTypography;
