import React from 'react';
import Box from '@mui/material/Box';
import type { ChildrenProp } from 'models/types/children-prop';

// import BgImage from 'assets/MainPageBg.png';

const LayoutBody: React.FC<ChildrenProp> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        flexGrow: 1

        // height: 'auto !important',
        // background: `url(${BgImage?.src}) no-repeat center center fixed; `,
        // backgroundSize: 'cover'
      }}
    >
      {children}
    </Box>
  );
};

export default LayoutBody;
