import withError from '../queryLifecycle/withError';
import { dropskinApi } from '../service';

export interface Giveaway {
  type: string;
  timer: string;
  [key: string]: any;
}

export const notificationsApi = dropskinApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<Giveaway, void>({
      query: () => ({
        url: '/notifications/',
        method: 'get'
      }),
      // async onQueryStarted(_, { dispatch, queryFulfilled }) {
      //   await withError({ dispatch, queryFulfilled });
      // }
      providesTags: ['notifications']
    }),
    markAsRead: builder.mutation<Giveaway, void>({
      query: (id) => ({
        url: `/notifications/read/${id}`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['notifications']
    }),
    markAllAsRead: builder.mutation<Giveaway, void>({
      query: () => ({
        url: '/notifications/read/all',
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['notifications']
    })
  })
});

export const { useGetNotificationsQuery, useMarkAsReadMutation, useMarkAllAsReadMutation } =
  notificationsApi;
