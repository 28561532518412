import MuiGlobalStyles from '@mui/material/GlobalStyles';

const GlobalStyles = () => (
  <MuiGlobalStyles
    styles={`
    html,
    body,
    body > div:first-child,
    div#__next,
    div#__next > div {
      height: 100%;
    }
    body: {
      margin: 0,
      padding: 0,
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '1.4em'
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey'
      }
  }`}
  />
);

export default GlobalStyles;
