import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isVolume: true,
  isBlitz: false
};

export const settingsReducer = createSlice({
  name: '@settings',
  initialState: INITIAL_STATE,
  reducers: {
    setVolume(state, { payload }) {
      state.isVolume = payload;
    },
    setBlitz(state, { payload }) {
      state.isBlitz = payload;
    }
  }
});

export const { setVolume, setBlitz } = settingsReducer.actions;

export default settingsReducer.reducer;
