import { useLayoutEffect } from 'react';
import { getCookie, getCookies } from 'cookies-next';
import { useAppDispatch } from 'store';
import { setAuth } from 'store/slices/auth';

const ACCESS_TOKEN = 'accessToken';

const AuthProvide = ({ children }) => {
  const token = getCookie(ACCESS_TOKEN);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (token) {
      dispatch(setAuth({ isAuthenticated: true, token, isLoading: false }));
    } else {
      dispatch(setAuth({ isAuthenticated: false, token: '', isLoading: false }));
    }
  }, [token]);

  return children;
};

export default AuthProvide;
