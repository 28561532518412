import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Theme, useMediaQuery } from '@mui/material';

import SteamIcon from 'oldAssets/social/Steam.svg';
import { useLoginModalContext } from 'context/modals/LoginModalProvider/LoginModalProvider';

const LogInButton: React.FC<ButtonProps & { fullText?: boolean }> = ({
  fullText = false,
  ...props
}) => {
  const { showLoginModal } = useLoginModalContext();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.down('lpxs'));
  const hidden = !fullText && isDesktop;

  return (
    <Button
      variant='contained'
      color='secondary'
      startIcon={<SteamIcon />}
      onClick={showLoginModal}
      sx={{
        height: {
          phxs: 40,
          phlg: 48
        }
      }}
      {...props}
    >
      Sign In {!hidden && 'With Steam'}
    </Button>
  );
};

export default LogInButton;
