import { togglePopup, StorePopupKeys } from 'store/slices/popups';
import { Dispatch } from 'redux';
import { notify } from 'store/slices/notifier';
import { setAuth } from 'store/slices/auth';
import { removeCookies } from 'cookies-next';

interface WithErrorProps {
  dispatch: Dispatch;
  queryFulfilled: Promise<any>;
}

enum CustomCodesType {
  KYC_CARD_CODE = 'KYC_CARD_CODE',
  KYC_CODE = 'KYC_CODE'
}
type CustomKeys = keyof typeof CustomCodesType;

type CodesType = {
  [key in CustomKeys]: StorePopupKeys;
};

const CustomCodes: CodesType = {
  KYC_CARD_CODE: 'kycVerification',
  KYC_CODE: 'kyc'
};

async function withError({ dispatch, queryFulfilled }: WithErrorProps) {
  try {
    const { data } = await queryFulfilled;
    return data;
  } catch (err) {
    const customCode: CustomKeys = err?.error?.data?.customCode;
    if (customCode) {
      dispatch(togglePopup({ key: CustomCodes[customCode], value: true }));
    } else {
      const statusCode =
        err?.error?.originalStatus || err?.error?.status || err?.error?.data?.statusCode;

      if (statusCode === 201) return;
      let msg = err?.error?.data?.message;

      if (!msg) {
        if (statusCode === 401) {
          msg = 'Please login firstly!';
          dispatch(setAuth({ isAuthenticated: false, isLoading: false, token: '' }));
          removeCookies('accessToken');
        }
        if (statusCode === 403) msg = 'Access is forbidden!';
        if (statusCode === 409) msg = 'Too Many Requests!';
        if (statusCode >= 500) msg = 'Server does not respond. Please try again later!';
      }

      dispatch(notify({ message: msg, type: 'warning' }));
    }
  }
  return null;
}

export default withError;
