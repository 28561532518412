import IconButton from '@mui/material/IconButton';

import useUser from 'hooks/useUser';
import LogoutIcon from 'oldAssets/actions/Exit.svg';

const LogoutButton = () => {
  const { signOut } = useUser();

  return (
    <IconButton
      aria-label='logout'
      onClick={signOut}
      sx={(theme) => ({
        height: 40,
        width: 40,
        p: 0,
        '&:hover': {
          backgroundColor: 'transparent',
          path: { fill: theme.palette.grey[400] }
        },
        bgcolor: theme.palette.grey[700]
      })}
    >
      <LogoutIcon />
    </IconButton>
  );
};

export default LogoutButton;
