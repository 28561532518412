import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { NAV_LINKS, EVENT_LINK, USER_GIVEAWAY_LINK } from 'constants/nav-links';
import { useRouter } from 'next/router';
import NavLink from './NavLink';
import { useGetActiveEventQuery } from 'store/rtk/events/service';
import { motion } from 'framer-motion';
import { useGetUserGiveawaysStatsQuery } from 'store/rtk/userGiveaways/service';

const DropPages: React.FC<BoxProps> = ({ ...props }) => {
  const { asPath } = useRouter();

  return (
    <Box gap={4} display='flex' sx={{ height: '100%', ml: 5 }} {...props}>
      {NAV_LINKS.map(({ icon, link, parentLink, text, isNew = false }, index) => (
        <Box display='flex' key={text + '-nav-links-bar-' + index}>
          <NavLink
            isNew={isNew}
            isActive={`/${asPath?.split('/')?.[1]}` === parentLink}
            link={link}
            text={text}
            icon={icon}
          />
        </Box>
      ))}

      <UserGiveawayLink />

      <EventDropLink />
    </Box>
  );
};

export default React.memo(DropPages);

const UserGiveawayLink = () => {
  const { asPath } = useRouter();

  const { data: userGiveaway } = useGetUserGiveawaysStatsQuery();

  if (!userGiveaway) return null;

  return (
    <Box display='flex' component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <NavLink
        isNew={USER_GIVEAWAY_LINK.isNew || false}
        isActive={`/${asPath?.split('/')?.[1]}` === USER_GIVEAWAY_LINK.parentLink}
        link={USER_GIVEAWAY_LINK.link}
        text={USER_GIVEAWAY_LINK.text}
        icon={USER_GIVEAWAY_LINK.icon}
      />
    </Box>
  );
};

const EventDropLink = () => {
  const { asPath } = useRouter();

  const { data: event } = useGetActiveEventQuery();

  if (!event) return null;

  return (
    <Box
      display='flex'
      component={motion.div}
      initial={{ y: -80 }}
      animate={{ y: 0 }}
      transition={{ delay: 0.8 }}
    >
      <NavLink
        isNew={EVENT_LINK.isNew || false}
        isActive={`/${asPath?.split('/')?.[1]}` === EVENT_LINK.parentLink}
        link={EVENT_LINK.link}
        text={EVENT_LINK.text}
        icon={EVENT_LINK.icon}
      />
    </Box>
  );
};
