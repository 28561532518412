import Box from '@mui/material/Box';
import { Typography, TypographyProps } from '@mui/material';

import { useAppSelector } from 'store';
import {
  useGetActiveEventQuery,
  useGetEventTokensActiveQuery,
  useGetUserBalanceEventQuery
} from 'store/rtk/events/service';
import Image from 'next/image';
import { EVENT_COLOR, EVENT_TOKEN } from 'constants/event/details';

const SIZES = {
  tokenSize: {
    small: 16,
    medium: 24,
    large: 32
  },
  typographyVariants: {
    small: 'body1',
    medium: 'subtitle1',
    large: 'h6'
  } as TypographyProps
};

interface EventTokensProps {
  size?: 'small' | 'medium' | 'large';
  count?: number;
}
interface UserEventTokensProps {
  size?: 'small' | 'medium' | 'large';
}

const EventTokens = ({ size = 'medium', count = 0 }: EventTokensProps) => {
  const { data: activeToken } = useGetEventTokensActiveQuery();

  if (!activeToken) return <></>;

  return (
    <Box display='flex' alignItems='center' gap={0.5} whiteSpace='nowrap'>
      <EventLogo
        width={SIZES.tokenSize[size]}
        height={SIZES.tokenSize[size]}
        icon={activeToken?.icon}
        title={activeToken?.title}
      />
      <Typography variant={SIZES.typographyVariants[size]} color={EVENT_COLOR} fontWeight={500}>
        {Number(count)?.toLocaleString('eu-US')?.replaceAll(',', ' ')}
      </Typography>
    </Box>
  );
};

export default EventTokens;

export const EventTokenIcon = ({ size = 'medium', count = 0 }: EventTokensProps) => {
  const { data: activeToken } = useGetEventTokensActiveQuery();

  if (!activeToken) return <></>;

  return (
    <EventLogo
      width={SIZES.tokenSize[size]}
      height={SIZES.tokenSize[size]}
      title={activeToken?.title}
    />
  );
};

export const UserEventTokens = ({ size = 'medium' }: UserEventTokensProps) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const { data: event } = useGetActiveEventQuery();

  const { data: userTokens } = useGetUserBalanceEventQuery(undefined, {
    skip: !isAuthenticated || !event
  });

  if (!event || userTokens === null) return null;

  return (
    <>
      <EventTokens size={size} count={userTokens} />
    </>
  );
};

export const EventLogo = ({ width = 24, height = 24, title = '' }) => {
  return (
    <Box
      width={width}
      height={height}
      position='relative'
      // display='flex'
      // justifyContent='center'
      // alignItems='center'
    >
      <Image
        priority
        unoptimized
        loading='eager'
        alt={'event-logo'}
        src={EVENT_TOKEN?.image}
        key={`next-image-token-${title}`}
        fill
        style={{
          objectFit: 'contain',
          width: '100%',
          height: '100%',
          display: 'block'
        }}
      />
    </Box>
  );
};
