import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, IconButton, useMediaQuery } from '@mui/material';

import { useGetUserQuery } from 'store/rtk/service';
import LogoutButton from 'components/LogoutButton';
import UserNotifications from './UserNotifications';
import UserDetails from '../components/UserDetails';
import FundsButton from '../components/FundsButton';
import { UserEventTokens } from 'components/User/EventTokens';
import { SITE_LINKS } from 'constants/nav-links';
import Link from 'next/link';

const UserAccount = ({ hiddens = false }) => {
  const hideButtonText = useMediaQuery((theme) => theme.breakpoints.down('dt'));

  const { data: user, isLoading } = useGetUserQuery();

  if (isLoading) return <CircularProgress />;

  return (
    <Box
      gap={3}
      display='flex'
      sx={{
        alignItems: 'center',
        justifyContent: {
          phxs: 'flex-start',
          lpxs: 'center'
        },
        flexDirection: 'row',
        width: {
          phxs: '100%',
          tbxs: 'inherit'
        }
      }}
    >
      <UserNotifications />
      {/* <DropSkinTokens /> */}
      <Box display={{ phxs: 'none', tb: 'block' }}>
        <UserEventTokens />
      </Box>
      <UserDetails avatar={user?.avatarMedium} name={user?.name} isVerified={user?.isVerified} />

      {!hiddens && (
        <>
          <FundsButton hideText={hideButtonText} />

          <Box display='flex' gap={1}>
            <Link href={SITE_LINKS?.support?.link} passHref>
              <IconButton
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: (theme) => theme.palette.grey[700],
                  path: { fill: SITE_LINKS?.support?.color }
                }}
              >
                {SITE_LINKS?.support?.icon}
              </IconButton>
            </Link>
            <LogoutButton />
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserAccount;
