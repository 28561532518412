import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import VerifiedIcon from 'assets/icon24/Verified.svg';

import Balance from '../UserAccount/Balance';

const UserDetails = ({ avatar, name, showName = false, isVerified = false }) => {
  return (
    <Link shallow href={'/account'} as={'/account'} passHref>
      <Box
        display='flex'
        gap={1}
        sx={{
          cursor: 'pointer'
        }}
      >
        <Avatar
          alt='steam-avatar'
          src={avatar}
          variant='rounded'
          sx={{
            width: 48,
            height: 48,
            borderRadius: 2
          }}
        />
        <Box
          display={{
            phxs: showName ? 'flex' : 'none',
            tbxs: 'flex',
            tblg: 'none',
            lp: 'flex'
          }}
          flexDirection='column'
          alignItems='flex-start'
        >
          <Box display={'flex'} alignItems={'center'} gap={1} width={1}>
            <Typography variant='body1'>{name}</Typography>

            {isVerified && (
              <Box display={'flex'} sx={{ svg: { width: 24, height: 24 } }}>
                <VerifiedIcon />
              </Box>
            )}
          </Box>
          <Balance />
        </Box>
      </Box>
    </Link>
  );
};

export default UserDetails;
