import withError from '../queryLifecycle/withError';
import { dropskinApi } from '../service';

export const eventsApi = dropskinApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveEvent: builder.query<EventActiveResponse, void>({
      query: () => ({
        url: `/events/event/active`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    getUserBalanceEvent: builder.query<any, void>({
      query: () => ({
        url: `/events-auth/event-transactions/balance`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['user-token']
    }),
    getEventTokensActive: builder.query<TokensActiveResponse, void>({
      query: () => ({
        url: `/events/tokens/active`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    getEventCaseByName: builder.query<CaseByName, string | string[]>({
      query: (name) => ({
        url: `/events/cases/name/${name}/info`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    // ---------- SCRATCH_GAME ------------
    playScratchGame: builder.mutation<any, { level: string }>({
      query: ({ level }) => ({
        url: `/events-auth/event-scratch/play`,
        body: { level },
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['user-token']
    }),
    getScratchResult: builder.mutation<ScratchResultResponse, { gameId: number; place: number }>({
      query: ({ gameId, place }) => ({
        url: `/events-auth/event-scratch/redeem/${gameId}`,
        params: { place },
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    getScratchRecentDrop: builder.query<any[], void>({
      query: () => ({
        url: `/events/scratch/last?take=12`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
      // providesTags: ['scratch-recent-drop']
    }),
    // ---------- REFILL ------------
    getRefillList: builder.query<RefillListResponse[], void>({
      query: () => ({
        url: `/events/refill-list`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    getUserRefillList: builder.query<{ deposit: number; list: RefillListResponse[] }, void>({
      query: () => ({
        url: `/events-auth/refill-auth/claimed`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['user-refill-list']
    }),
    claimRefillQuest: builder.mutation<any, { refillId: number }>({
      query: ({ refillId }) => ({
        url: `/events-auth/refill-auth/claim`,
        body: { refillId },
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['user-token', 'user-refill-list']
    }),
    //---------- TOKEN_BANK ------------
    getTokenBankTop: builder.query<any, void>({
      query: () => ({
        url: `/events/token-bank/top`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['event-token-bank-top']
    }),
    getTokenBankUser: builder.query<any, void>({
      query: () => ({
        url: `/events-auth/bank-auth/me/amount`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['event-token-bank-user']
    }),
    depositTokenBank: builder.mutation<any, { amount: number }>({
      query: ({ amount }) => ({
        url: `/events-auth/bank-auth/deposit`,
        body: { amount },
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['user-token', 'event-token-bank-user', 'event-token-bank-top']
    }),
    //---------- SKIN_GENERATOR ------------
    generateSkin: builder.mutation<any, { amount: number }>({
      query: ({ amount }) => ({
        url: `/events-auth/exchanger-auth`,
        body: { amount },
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: [
        'user-token',
        'accountInventory',
        'inventoryAmount',
        'upgraderInventory'
        // 'skin-generator-recent-drop'
      ]
    }),
    getSkinGeneratorRecentDrop: builder.query<any[], void>({
      query: () => ({
        url: `/events/exchanger/last?take=12`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
      // providesTags: ['skin-generator-recent-drop']
    }),
    //---------- EVENT_STORE ------------
    getEventStore: builder.query<EventStoreResponse, void>({
      query: () => ({
        url: `/events/event-store`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
      // providesTags: ['event-store']
    }),
    buyEventStoreItem: builder.mutation<any, { itemId: number }>({
      query: ({ itemId }) => ({
        url: `/events-auth/store-auth/buy`,
        body: { itemId },
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: [
        'user-token',
        'balance',
        'accountInventory',
        'inventoryAmount',
        'upgraderInventory'
      ]
    })
  })
});

export const {
  // ---------- EVENT_ACTIVE ------------
  useGetActiveEventQuery,
  useGetUserBalanceEventQuery,
  useGetEventTokensActiveQuery,
  useGetEventCaseByNameQuery,
  // ---------- SCRATCH_GAME ------------
  usePlayScratchGameMutation,
  useGetScratchResultMutation,
  useGetScratchRecentDropQuery,
  // ---------- REFILL ------------
  useGetRefillListQuery,
  useGetUserRefillListQuery,
  useClaimRefillQuestMutation,
  // ---------- REFILL ------------
  useGetTokenBankTopQuery,
  useGetTokenBankUserQuery,
  useDepositTokenBankMutation,
  //---------- SKIN_GENERATOR ------------
  useGenerateSkinMutation,
  useGetSkinGeneratorRecentDropQuery,
  // ---------- EVENT_STORE ------------
  useGetEventStoreQuery,
  useBuyEventStoreItemMutation,
  util: { getRunningOperationPromises }
} = eventsApi;

export const { getActiveEvent, getEventCaseByName } = eventsApi.endpoints;

// ---------- TYPES ------------
// -----------------------EVENT-ACTIVE------------------------------

export interface Colors {
  token: string;
  buttons: string;
}

export interface MainPage {
  bgImg: string;
  titleImg: string;
}

export interface Home {
  tabImg: string;
  bgImg: string;
  titleImg: string;
}

export interface TokenBank {
  tabImg: string;
  bgImg: string;
  objectImg: string;
}

export interface Template {
  name: string;
  price: number;
  img: string;
}

export interface Cards {
  tabImg: string;
  howItWorks: { bgImg: string };
  templates: Template[];
}

export interface MainObject {
  topImg: string;
  bottomImg: string;
}

export interface SkinGenerator {
  tabImg: string;
  bgImg: string;
  mainObject: MainObject;
}

export interface EventStore {
  tabImg: string;
  bgImg: string;
}

export interface Tabs {
  activeTabColor: string;
  home: Home;
  tokenBank: TokenBank;
  cards: Cards;
  skinGenerator: SkinGenerator;
  eventStore: EventStore;
}

export interface Extra {}

export interface Token {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  icon: string;
  color: string;
  title: string;
  depositRate: number;
  status: boolean;
  extra: Extra;
}

export interface Event {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  name: string;
  description: string;
  earnType: string;
  caseRateToken: number;
  storeRateToken: number;
  feeExchange: number;
  colors: Colors;
  mainPage: MainPage;
  tabs: Tabs;
  howToPlay?: any;
  eventStart: string;
  eventEnd: string;
  token: Token;
}

export interface Case {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  position: number;
  category: string;
  isActive: boolean;
  price: number;
  maxCaseUsd: string;
  maxCaseTokens: string;
  caseImg?: any;
  rifleImg?: any;
}

export interface EventActiveResponse {
  event: Event;
  cases: {
    main: Case[];
    special: Case[];
  };
}

// -----------------------SCRATCH------------------------------

type ScratchResultResponse = {
  winItem: WinItem;
  items: Item[];
};
interface WinItem {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  price: number;
  steamMarketPrice: number;
  amount?: any;
  steamMarketHashName: string;
  exterior: string;
  type: string;
  collection: string;
  volume: number;
  icon: string;
  rarityName: string;
  qualityName: string;
  weaponType: string;
  itemName: string;
}
interface Item {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  price: number;
  steamMarketPrice: number;
  amount?: any;
  steamMarketHashName: string;
  exterior: string;
  type: string;
  collection: string;
  volume: number;
  icon: string;
  rarityName: string;
  qualityName: string;
  weaponType: string;
  itemName: string;
}

// -----------------------TOKENS------------------------------

export interface TokensActiveResponse {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  icon: string;
  color: string;
  title: string;
  depositRate: number;
  status: boolean;
  extra: unknown;
}

// -----------------------REFILL------------------------------

export interface RefillEvent {
  name: string;
  id: number;
}

export interface RefillExtra {
  event?: RefillEvent;
}

export interface RefillListResponse {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  amount: number;
  tokenReward: number;
  extra?: RefillExtra;
  canClaim?: boolean;
  claimed?: boolean;
}

// -----------------------EVENT_STORE------------------------------

export interface Store {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  active: boolean;
}

export interface EventStoreItem {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  price: number;
  steamMarketPrice: number;
  amount?: any;
  steamMarketHashName: string;
  exterior: string;
  type: string;
  collection: string;
  volume: number;
  icon: string;
  rarityName: string;
  qualityName: string;
  weaponType: string;
  itemName: string;
}

export interface StoreItem {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  tokenPrice: number;
  item: EventStoreItem;
  type: string;
}

export interface EventStoreResponse {
  store: Store;
  storeItems: StoreItem[];
}
