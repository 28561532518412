import React from 'react';
// import Box from '@mui/material/Box';
import Links from './Links';
import LastDrops from './LastDrops';
import { useMediaQuery } from '@mui/material';
import SiteContainer from 'components/SiteContainer';
// import CContainer from 'components/CContainer';

const ActionsBar: React.FC = () => {
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('tblg'));

  return (
    // <Box
    //   display='flex'
    //   alignItems='center'
    //   // flexDirection='column'
    // >
    <SiteContainer>
      {!hidden && <Links />}
      <LastDrops />
    </SiteContainer>
    // </Box>
  );
};

export default ActionsBar;
