import React, { useEffect, useState } from 'react';
import { Box, Typography, Tabs, Tab, useTheme } from '@mui/material';

import NotificationsHeader from './NotificationsHeader';
import NotificationsList from './NotificationsList';
import { NOTIFICATIONS_TABS } from 'constants/notifications-modal/notifications';

const NotificationsBody = ({ list = [], notReadCount = 0 }) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<keyof typeof NOTIFICATIONS_TABS>(
    NOTIFICATIONS_TABS.PUBLIC
  );
  const [listState, setListState] = useState([]);

  useEffect(() => {
    const filteredList = list.filter((item) => item?.type === activeTab);
    setListState(filteredList);
  }, [activeTab, list]);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: keyof typeof NOTIFICATIONS_TABS
  ) => {
    setActiveTab(newValue);
  };

  return (
    <Box display='flex' flexDirection='column' gap={1} height={1} width={1}>
      <Box width={1}>
        <NotificationsHeader
          disableReadMark={activeTab !== NOTIFICATIONS_TABS.PRIVATE || notReadCount <= 0}
        />
        <Tabs
          value={activeTab}
          onChange={handleChange}
          textColor='primary'
          indicatorColor='primary'
          variant='fullWidth'
          aria-label='notifications-tabs'
          sx={{
            minHeight: 40,
            height: 40,
            borderBottom: `1px solid ${theme.palette.grey[600]}`,
            '& .MuiTab-root.Mui-selected .MuiTypography-body1': {
              color: theme.palette.primaries[400]
            }
          }}
        >
          <Tab
            value={NOTIFICATIONS_TABS.PUBLIC}
            label={<TabLabel text={NOTIFICATIONS_TABS.PUBLIC} />}
            sx={{
              minHeight: 40,
              height: 40
            }}
          />
          <Tab
            value={NOTIFICATIONS_TABS.PRIVATE}
            label={<TabLabel text={NOTIFICATIONS_TABS.PRIVATE} count={notReadCount} />}
            sx={{
              minHeight: 40,
              height: 40
            }}
          />
        </Tabs>
      </Box>

      <NotificationsList list={listState} />
    </Box>
  );
};

export default NotificationsBody;

//------------------------

const TabLabel = ({ text = '', count = 0 }) => {
  const theme = useTheme();

  return (
    <Box
      display='flex'
      sx={{
        '& .MuiTypography-body1': {
          color: theme.palette.grey[300],
          display: 'inline-block',
          textTransform: 'lowercase',
          '&.Mui-selected': {
            color: theme.palette.primaries[400]
          }
        },
        '& .MuiTypography-body1:first-letter': {
          textTransform: 'uppercase'
        }
      }}
    >
      <Typography variant='body1'>{text}</Typography>
      {count > 0 && (
        <Box height={1} display='flex' alignItems='center' justifyContent='center' pl={1}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height={22}
            p='2px 8px'
            borderRadius='22px'
            fontSize={10}
            bgcolor={theme.palette.drop.red}
            border='1px solid rgba(0, 0, 0, 0.14)'
          >
            <Typography variant='overline' color={theme.palette.white}>
              {count}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
