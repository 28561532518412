import withError from '../queryLifecycle/withError';
import { dropskinApi } from '../service';

export const partnerApi = dropskinApi.injectEndpoints({
  endpoints: (builder) => ({
    getRefConfig: builder.query({
      query: () => ({
        url: `/ref/config`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    getRefLevel: builder.query({
      query: () => ({
        url: `/ref-auth/me/level`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    getRefCount: builder.query({
      query: () => ({
        url: `/user-auth/me/referrals-count`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    getCashBalance: builder.query({
      query: () => ({
        url: `/account/cash/me`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    setReferralLink: builder.mutation<void, string>({
      query: (referral) => ({
        url: '/user-auth/set/referral-url',
        method: 'post',
        body: { referral }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['user']
    }),
    setReferral: builder.mutation<void, string>({
      query: (referral) => ({
        url: `/user-auth/set/referral/${referral}`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['referral']
    })
  })
});

export const {
  useGetRefCountQuery,
  useGetCashBalanceQuery,
  useGetRefLevelQuery,
  useGetRefConfigQuery,
  useSetReferralLinkMutation,
  useSetReferralMutation
} = partnerApi;
