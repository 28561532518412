import { Box } from '@mui/material';
import React from 'react';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import { FOOTER_LINKS } from 'constants/footer-links';
import { useLoginModalContext } from 'context/modals/LoginModalProvider/LoginModalProvider';
import { useAppSelector } from 'store';

const FooterNav: React.FC = () => {
  const isAuthenticated = useAppSelector((s) => s.auth.isAuthenticated);

  return (
    <Box display='flex' alignItems='center' justifyContent='space-evenly' flexWrap='wrap' gap={3}>
      {FOOTER_LINKS.map(({ link, text, isAuth }) =>
        isAuth && !isAuthenticated ? (
          <Box display='flex' key={text + '-footer-nav-links'}>
            <AuthLink text={text} />
          </Box>
        ) : (
          <Box display='flex' key={text + '-footer-nav-links'}>
            <NavLink link={link} text={text} />
          </Box>
        )
      )}
    </Box>
  );
};

export default FooterNav;

const AuthLink = ({ text }) => {
  const { showLoginModal } = useLoginModalContext();

  return (
    <Box display={'flex'} onClick={showLoginModal}>
      <Typography
        variant='body1'
        fontWeight={300}
        whiteSpace='nowrap'
        sx={{
          cursor: 'pointer'
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const NavLink = ({ link, text }) => {
  return (
    <Link shallow href={link} as={link} passHref>
      <Typography
        variant='body1'
        fontWeight={300}
        whiteSpace='nowrap'
        sx={{
          cursor: 'pointer'
        }}
      >
        {text}
      </Typography>
    </Link>
  );
};
