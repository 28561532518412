import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { IconButton, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { useAppSelector } from 'store';
import LogInButton from 'components/LogInButton';
import DropSkinLogo from 'components/Logo';
import SiteContainer from 'components/SiteContainer';
import DropPages from './DropPages';
import UserAccount from './UserAccount';

import dynamic from 'next/dynamic';
import { SITE_LINKS } from 'constants/nav-links';
import Link from 'next/link';

const Drawer = dynamic(() => import('./Drawer/Drawer'), {
  // suspense: true,
  ssr: false
});

const DrawerButton = dynamic(() => import('./Drawer/DrawerButton'), {
  // suspense: true,
  ssr: false
});

const HEADER_HEIGHT = { phxs: 64, tbxs: 80 };

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  bgcolor: theme.palette.grey[300],
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: theme.palette.greyTransparency[900],
  borderBottom: `1px solid ${theme.palette.greyTransparency[700]}`,
  backdropFilter: 'blur(24px)',
  boxShadow: 'none'
}));

// https://levelup.gitconnected.com/how-to-create-a-navigation-bar-with-material-ui-9cbcfcec2570
const NavBar: React.FC = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('tblg'));
  const [openedDrawer, setOpenedDrawer] = useState(false);

  return (
    <>
      <StyledAppBar sx={{ height: HEADER_HEIGHT }}>
        <SiteContainer>
          <Toolbar disableGutters sx={{ height: '100%', width: '100%' }}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              width={1}
              height={1}
            >
              {/* <DropSkinNewYear /> */}
              <DropSkinLogo />
              <Box
                // ml={{ phxs: 0, dt: 5 }}
                gap={4}
                height={1}
                display={{ phxs: 'none', tblg: 'flex' }}
              >
                <DropPages ml={0} />
              </Box>

              <Box sx={{ flexGrow: 1 }} />

              <Box display='flex' alignItems='center' height={1} gap={1}>
                {!isAuthenticated ? <LogInButton /> : <UserAccount hiddens={hidden} />}
                {hidden && <DrawerButton setDrawer={setOpenedDrawer} />}

                {!isAuthenticated && !hidden && (
                  <Link href={SITE_LINKS?.support?.link} passHref>
                    <IconButton
                      sx={{
                        width: 40,
                        height: 40,
                        bgcolor: (theme) => theme.palette.grey[700],
                        path: { fill: SITE_LINKS?.support?.color }
                      }}
                    >
                      {SITE_LINKS?.support?.icon}
                    </IconButton>
                  </Link>
                )}
              </Box>
            </Box>
          </Toolbar>
        </SiteContainer>
        {hidden && <Drawer isOpen={openedDrawer} setDrawer={setOpenedDrawer} />}
      </StyledAppBar>
      <Toolbar sx={{ minHeight: HEADER_HEIGHT }} />
    </>
  );
};

export default NavBar;
