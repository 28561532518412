import Box, { BoxProps } from '@mui/material/Box';
import Link from 'next/link';
import { SOCIAL_LINKS } from 'constants/nav-links';

const SocialLinks = ({ ...props }: BoxProps) => {
  return (
    <Box display='flex' alignItems='center' gap={{ phxs: 2, lpxs: 3 }} {...props}>
      {SOCIAL_LINKS.map(({ icon, link, color }) => (
        <Link
          shallow
          href={link}
          as={link}
          key={link}
          passHref
          target={'_blank'}
          rel='noopener noreferrer'
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{
              cursor: 'pointer',
              height: 24,
              width: 24,
              path: {
                fill: (theme) => theme.palette.grey[400]
              },
              ':hover path': {
                fill: color
              }
            }}
          >
            {icon}
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default SocialLinks;
