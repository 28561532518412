import { PaletteOptions } from '@mui/material';
import {
  IconButtonPropsColorOverrides,
  IconButtonPropsSizeOverrides
} from '@mui/material/IconButton';
import spacing from '../utils/spacing';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
    link: true;
  }
  // interface IconButtonPropsSizeOverrides {
  //   dashed: true;
  // }
}

const MuiIconButton = (palette: PaletteOptions) => {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          // width: spacing(6),
          // height: spacing(6),
          borderRadius: 8,
          // backgroundColor: palette.grey[300]
        }
      },
      variants: [
        // {
        //   props: { color: 'primary' },
        //   style: {
        //     height: 8,
        //     borderRadius: 5
        //   }
        // },
        // {
        //   props: { color: 'secondary' },
        //   style: {
        //     height: 8,
        //     borderRadius: 5
        //   }
        // },
        // {
        //   props: { color: 'tertiary' },
        //   style: {
        //     height: 8,
        //     borderRadius: 5,
        //     path: {
        //       fill: 'green'
        //     }
        //   }
        // },

        //----------- SIZES -----------
        {
          props: { size: 'small' },
          style: {
            width: spacing(5),
            height: spacing(5),
            svg: {
              width: 'auto',
              height: '16px'
            }
          }
        },
        {
          props: { size: 'large' },
          style: {
            width: spacing(7),
            height: spacing(7),
            svg: {
              width: 'auto',
              height: '40px'
            }
          }
        }
      ]
    }
  };
};

export default MuiIconButton;
