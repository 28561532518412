import { createBreakpoints } from '@mui/system';
import { BREAKPOINTS } from './constants/breakpoints';

declare module '@mui/system' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    phxs: true; // adds the `phxs` breakpoint
    ph: true;
    phlg: true;
    tbxs: true;
    tb: true;
    tblg: true;
    lpxs: true;
    lp: true;
    lplg: true;
    dtxs: true;
    dt: true;
    dtlg: true;
  }
}

declare module '@mui/material' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    phxs: true; // adds the `phxs` breakpoint
    ph: true;
    phlg: true;
    tbxs: true;
    tb: true;
    tblg: true;
    lpxs: true;
    lp: true;
    lplg: true;
    dtxs: true;
    dt: true;
    dtlg: true;
  }
}

export const breakpointValues = {
  values: BREAKPOINTS,
};

export const breakpoints = createBreakpoints({
  ...breakpointValues,
  step: 5,
  unit: 'px',
});
