import { PaletteOptions } from '@mui/material';


const MuiPaginationItem = (palette: PaletteOptions) => {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        '.MuiPaginationItem-root': {
          backgroundColor: 'rgba(100,100,100,0.5)',
          color: '#ff0000'
        },
        root: {
          color: '#fff',
          '& .Mui-selected': {
            backgroundColor: 'transparent',
            color: '#19D5C6'
          }
        }
      },
      variants: [
        {
          props: { variant: 'determinate', color: 'secondary' },
          style: {
            height: 8,
            borderRadius: 5
          }
        }
      ]
    }
  };
};

export default MuiPaginationItem;
