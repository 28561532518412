import { PaletteOptions } from '@mui/material';
import spacing from '../utils/spacing';

const MuiCheckbox = (palette: PaletteOptions) => {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          // backgroundColor: palette.grey[300]
          width: 24,
          height: 24
        }
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            // color: palette.primaries[500],

            borderRadius: 4,
            // width: 16,
            // height: 16,

            background: palette.grey[800],
            border: `2px solid ${palette.grey[700]}`,

            // // boxShadow:
            // //   palette.mode === 'dark'
            // //     ? '0 0 0 1px rgb(16 22 26 / 40%)'
            // //     : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            // backgroundColor: '#00ffff',
            backgroundImage: palette.grey[700]
            // '.Mui-focusVisible &': {
            //   outline: '2px auto rgba(19,124,189,.6)',
            //   outlineOffset: 2
            // },
            // 'input:hover ~ &': {
            //   backgroundColor: palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
            // },
            // 'input:disabled ~ &': {
            //   // boxShadow: 'none',
            //   backgroundColor: palette.grey[800]
            // },
            // '&.Mui-disabled': {
            //   // boxShadow: 'none',
            //   backgroundColor: palette.grey[800]
            // }
          }
        },
        {
          props: { color: 'secondary' },
          style: {
            color: 'red'
          }
        }
      ]
    }
  };
};

export default MuiCheckbox;
