import withError from '../queryLifecycle/withError';
import { dropskinApi } from '../service';

export const depositApi = dropskinApi.injectEndpoints({
  endpoints: (builder) => ({
    activateKinguin: builder.mutation<any, string>({
      query: (key) => ({
        url: `/kinguin/activate`,
        body: {
          key
        },
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    coinbaseDeposit: builder.mutation<{ url: string }, number>({
      query: (amount) => ({
        url: `/coinbase/create`,
        body: {
          amount
        },
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    shadowPay: builder.mutation<{ url: string }, void>({
      query: () => ({
        url: `/shadowpay/pay`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    skinifyPay: builder.mutation<{ url: string }, void>({
      query: () => ({
        url: `/skinify/pay`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    tap2pay: builder.mutation<{ amount: number; currency: string }, any>({
      query: ({ amount, currency }) => ({
        url: `/tap2pay/invoice`,
        method: 'post',
        body: {
          amount,
          currency
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    whitepay: builder.mutation<{ url: string }, { amount: number }>({
      query: ({ amount }) => ({
        url: `/whitepay/create`,
        method: 'post',
        body: {
          amount
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    zen: builder.mutation<{ redirect_url: string }, { amount: number | string }>({
      query: ({ amount }) => ({
        url: `/zen/payment`,
        method: 'post',
        body: {
          amount: String(amount)
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    payPalych: builder.mutation<{ redirect_url: string }, { amount: number; email: string }>({
      query: ({ amount, email }) => ({
        url: `/pay-palych/invoice`,
        method: 'post',
        body: {
          amount,
          email
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      transformResponse: (res: any) => {
        res.redirect_url = res.link_page_url;
        return res;
      }
    }),
    getReferalCode: builder.query<any, void>({
      query: () => ({
        url: `/user-auth/me/bonuses`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['depositPromo']
    })
  })
});

export const {
  useActivateKinguinMutation,
  useCoinbaseDepositMutation,
  useShadowPayMutation,
  useSkinifyPayMutation,
  useGetReferalCodeQuery,
  useTap2payMutation,
  useWhitepayMutation,
  useZenMutation,
  usePayPalychMutation
} = depositApi;
