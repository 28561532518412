import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { dropskinApiMiddleware } from 'store/rtk/service';
import rootReducers from './reducers';

const otherMiddleware = [dropskinApiMiddleware];

const getMiddlewares = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
  const middlewares = getDefaultMiddleware().concat(otherMiddleware);
  return middlewares;
};

const makeStore = () =>
  configureStore({
    reducer: rootReducers,
    middleware: getMiddlewares
  });

export const store = makeStore();

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const wrapper = createWrapper<AppStore>(makeStore, {});
