import React, { createContext, useContext, useEffect, useState } from 'react';

import isBoolean from 'lodash/isBoolean';

import PromoModal from 'components/Modals/PromoModal';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  useActivatePromoCaseMutation,
  useActivatePromoCodeMutation
} from '../../../store/rtk/promos/service';
import { useRouter } from 'next/router';
import { lowerCaseName } from '../../../utils/string';
import { notify } from '../../../store/slices/notifier';
import RecaptchaPopup from 'components/Google/RecaptchaPopup';

type PromocodeModalProviderProps = {
  children: React.ReactNode;
};

type UsePromocodeModalContext = {
  promoState: string;
  setPromoState: React.Dispatch<React.SetStateAction<string>>;
  activatePromoCode: (value: string, token: string) => void;
  handleApplyPromo: () => void;
  handleModal: (e?: boolean) => void;
  showPromocodeModal: () => void;
  hidePromocodeModal: () => void;
};

const PROMO_CODE = 'promo_code';

export const PromocodeModalContext = createContext({} as UsePromocodeModalContext);

export const usePromocodeModalContext = (): UsePromocodeModalContext =>
  useContext(PromocodeModalContext);

const PromocodeModalProvider = ({ children }: PromocodeModalProviderProps) => {
  const [modalState, setModalState] = useState(false);
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const [isRecaptchaOpen, setRecaptchaOpen] = useState(false);

  const { query, push } = useRouter();
  const [promoState, setPromoState] = useState('');

  const [activatePromo] = useActivatePromoCodeMutation();
  const [activatePromoCaseCode] = useActivatePromoCaseMutation();

  const showPromocodeModal = () => {
    setModalState(true);
  };

  const hidePromocodeModal = () => {
    setModalState(false);
  };

  const handleModal = (even) => {
    setModalState((prev) => (isBoolean(even) ? even : !prev));
  };

  const handleApplyPromo = async () => {
    if (isAuthenticated) {
      setRecaptchaOpen(true); // Open the reCAPTCHA popup to get a new token
    }
  };

  const handleRecaptchaVerify = async (token: string) => {
    setRecaptchaOpen(false);

    if (promoState) {
      activatePromoCode(promoState, token);
    }
  };

  const activatePromoCode = async (value: string, captcha: string) => {
    try {
      const code = value?.trim();
      if (!value || !code) return;
      const isPromoCase = code.split('-').length === 4;

      if (!captcha) return;

      if (isPromoCase) {
        await activatePromoCaseCode({ code, captcha })
          .unwrap()
          .then((res) => {
            setPromoState('');
            dispatch(
              notify({
                message: `Successful Activate Promo Case ${res?.name}`,
                type: 'success'
              })
            );
            push(`/case/${lowerCaseName(res?.name)}`);
            hidePromocodeModal();
          });
      } else {
        await activatePromo({ code, captcha })
          .unwrap()
          .then(() => {
            setPromoState('');
            dispatch(notify({ message: `Successful Activate Promo Code`, type: 'success' }));
          });
      }
    } catch (e) {
      //
    }
  };

  useEffect(() => {
    const promo = (query?.promo as string) || localStorage.getItem(PROMO_CODE);

    if (promo) {
      setPromoState(promo);

      showPromocodeModal();

      if (!isAuthenticated) {
        localStorage.setItem(PROMO_CODE, promo);
      } else {
        handleApplyPromo(); // Open reCAPTCHA popup
        localStorage.removeItem(PROMO_CODE);
      }
    }
  }, [query, isAuthenticated]);

  return (
    <PromocodeModalContext.Provider
      value={{
        promoState,
        setPromoState,
        activatePromoCode,
        handleApplyPromo,
        handleModal,
        showPromocodeModal,
        hidePromocodeModal
      }}
    >
      {children}
      <PromoModal modalState={modalState} hideModal={hidePromocodeModal} />

      <RecaptchaPopup
        open={isRecaptchaOpen}
        onClose={() => setRecaptchaOpen(false)}
        onVerify={handleRecaptchaVerify}
      />
    </PromocodeModalContext.Provider>
  );
};

export default PromocodeModalProvider;
