import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { styled } from '@mui/material/styles';

type StyledNavBoxProps = {
  isActive: boolean;
};

const StyledNavBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<StyledNavBoxProps>(({ theme, isActive = false }) => ({
  display: 'flex',
  path: {
    fill: isActive ? theme.palette.drop.orange : theme.palette.grey[200]
  },
  '.MuiTypography-root': {
    color: isActive ? theme.palette.drop.orange : theme.palette.grey[200]
  },
  ':hover': {
    '.MuiTypography-root': {
      color: isActive ? theme.palette.drop.orange : theme.palette.grey[100]
    },
    path: {
      fill: isActive ? theme.palette.drop.orange : theme.palette.grey[100]
    }
  }
}));

const NavLink = ({ isNew = false, isActive = false, link = '/', text = '', icon }) => {
  return (
    <Link shallow href={link} as={link}>
      <StyledNavBox isActive={isActive}>
        <Box
          gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          {icon}
          <Typography variant='subtitle2' display={{ phxs: 'none', dt: 'block' }}>
            {text}
          </Typography>
        </Box>
        {isNew && (
          <Box height={1} display='flex' alignItems='center' justifyContent='center' pl={1}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              height={22}
              sx={{
                padding: '2px 8px',
                borderRadius: 21,
                border: '1px solid rgba(0, 0, 0, 0.14)',
                fontSize: 10,
                backgroundColor: (theme) => theme.palette.drop.red,
                color: (theme) => theme.palette.white
              }}
            >
              New
            </Box>
          </Box>
        )}
      </StyledNavBox>
    </Link>
  );
};

export default React.memo(NavLink);
