import { PaletteMode, Theme, ThemeOptions } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { createPalette } from './palette';
import createTypography from './typography';
import { breakpointValues } from './breakpoints';
import { zIndex } from './zIndex';
import createComponents from './components';

const getTheme = (mode: PaletteMode): ThemeOptions => {
  const palette = createPalette(mode);
  const typography = createTypography(palette);
  const components = createComponents(palette);

  return {
    zIndex,
    palette,
    typography,
    breakpoints: breakpointValues,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            maxWidth: '100vw',
            width: '100%',
            boxSizing: 'border-box',
            margin: '0',
            padding: '0'
          },
          body: {
            fontFamily: '"Kanit", sans-serif;',
            color: '#fff',
            overflowX: 'hidden'
          },
          a: {
            textDecoration: 'none'
          },

          /* width */
          '::-webkit-scrollbar': {
            // display: 'none',
            width: 8
          },

          /* Track */
          '::-webkit-scrollbar-track': {
            background: palette.grey[900]
          },

          /* Handle */
          '::-webkit-scrollbar-thumb': {
            background: palette.grey[700],
            borderRadius: 4
          },

          /* Handle on hover */
          '::-webkit-scrollbar-thumb:hover': {
            background: palette.grey[600]
          },

          '*': {
            scrollbarColor: '#fff !important'
            // scrollbarWidth: 'thin'
            // scrollbarWidth: 'none' /* Firefox */,
            // '-ms-overflow-style': 'none' /* Internet Explorer and Edge */
          }
        }
      },
      ...components
    }
  };
};

export const generateTheme = (mode: PaletteMode): Theme => {
  let theme = createTheme(getTheme(mode));
  theme = responsiveFontSizes(theme);
  return theme;
};
