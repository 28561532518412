import React from 'react';
import { useGetUserBalanceQuery } from 'store/rtk/service';
import Typography from '@mui/material/Typography';
import floor from 'lodash/floor';
import { useAppSelector } from 'store';

const Balance: React.FC = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { data: balance = 0 } = useGetUserBalanceQuery(undefined, { skip: !isAuthenticated });

  return (
    <Typography variant='body1' fontWeight={500} color={(theme) => theme.palette.drop.green}>
      ${floor(balance, 2)} USD
    </Typography>
  );
};

export default Balance;
