import React from 'react';
import Box from '@mui/material/Box';
import DiamondPaper from './DiamondPaper';
// import DropsList from './DropsList';
import dynamic from 'next/dynamic';

const DropsList = dynamic(() => import('./DropsList'), { ssr: false });

const LastDrops: React.FC = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      width={1}
      mt={1}
      position='relative'
      overflow='hidden'
      zIndex={1}
    >
      <Box
        display='flex'
        height={1}
        sx={{
          ':after': {
            position: 'absolute',
            right: '0px',
            content: '""',
            width: {
              phxs: 64,
              tb: 132
            },
            height: '100%',
            background: 'linear-gradient(270deg, #17171C 0%, rgba(27, 27, 29, 0.00) 100%)',
            // background: 'linear-gradient(270deg, #17171E 0%, rgba(23, 23, 30, 0.00) 100%)',
            zIndex: 1
          }
        }}
      >
        <DiamondPaper />
        <DropsList />
      </Box>
    </Box>
  );
};

export default LastDrops;
