import { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import moment from 'moment';
import { motion } from 'framer-motion';

import { useMarkAsReadMutation } from 'store/rtk/notifications/service';
import { MESSAGE_TYPES } from 'constants/notifications-modal/notifications';

const NotificationItem = ({
  notificationId,
  title = '',
  message = '',
  isRead = false,
  messageType,
  createdAt,
  isPrivate = false
}) => {
  const theme = useTheme();
  const [showMore, setShowMore] = useState(false);

  const [markAsRead] = useMarkAsReadMutation();

  const handleExpand = () => {
    setShowMore((prev) => !prev);
    if (isPrivate && !showMore && !isRead) {
      markAsRead(notificationId);
    }
  };

  const isLightText = !showMore && isPrivate && isRead;

  return (
    <Box
      display='flex'
      width={1}
      gap={1}
      p={theme.spacing(1, 2)}
      onClick={handleExpand}
      sx={{
        cursor: 'pointer',
        ':hover': {
          background: theme.palette.grey[700]
        }
      }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* ICON */}
      <Box display='flex' mt={1} height='fit-content'>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width={48}
          height={48}
          borderRadius='50%'
          bgcolor={theme.palette.grey[700]}
          border={`2px solid ${theme.palette.grey[600]}`}
          sx={{
            path: { fill: MESSAGE_TYPES[messageType].color }
          }}
        >
          {MESSAGE_TYPES[messageType].icon}
        </Box>
      </Box>
      {/* TEXT */}
      <Box
        display='flex'
        flexDirection='column'
        width={1}
        overflow='hidden'
        sx={{
          userSelect: 'none'
        }}
      >
        <Typography
          variant='subtitle2'
          color={isLightText ? theme.palette.grey[400] : theme.palette.white}
        >
          {title}
        </Typography>
        <Box display='flex' flexDirection='column'>
          <Typography
            variant='body1'
            color={isLightText ? theme.palette.grey[500] : theme.palette.grey[200]}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              wordWrap: 'break-word',
              whiteSpace: showMore ? 'normal' : 'nowrap'
            }}
          >
            {message}
          </Typography>
          {/* {showMore && <Box> SOME EXTRA DATA HERE </Box>} */}
        </Box>
        <Typography variant='body2' color={theme.palette.grey[500]}>
          {moment(createdAt).format('DD MMM YYYY HH:mm').toString()}
        </Typography>
      </Box>
    </Box>
  );
};

export default NotificationItem;
