import Box from '@mui/material/Box';

const SiteContainer = ({ children }) => {
  return (
    <Box
      display='flex'
      width={1}
      maxWidth='dt'
      alignItems='center'
      justifyContent='center'
      mx='auto'
      px={{
        phxs: 2,
        tbxs: 4,
        dt: '35px'
        // dtlg: '110px'
      }}
    >
      <Box
        width={1}
        height={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
      >
        {children}
      </Box>
    </Box>
  );
};

export default SiteContainer;
