import React, { createContext, Suspense, useContext, useState } from 'react';
import { useAppSelector } from 'store';
// import LoginDialog from 'components/LoginDialog';
import dynamic from 'next/dynamic';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  // suspense: true,
  ssr: false
});

type LoginModalProviderProps = {
  children: React.ReactNode;
};

type UseLoginModalContext = {
  showLoginModal: () => void;
  hideLoginModal: () => void;
};

export const LoginModalContext = createContext({} as UseLoginModalContext);

export const useLoginModalContext = (): UseLoginModalContext => useContext(LoginModalContext);

const LoginModalProvider = ({ children }: LoginModalProviderProps) => {
  const [openModal, setOpenModal] = useState(false);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const showLoginModal = () => {
    if (isAuthenticated) return;
    setOpenModal(true);
  };

  const hideLoginModal = () => {
    setOpenModal(false);
  };

  return (
    <LoginModalContext.Provider value={{ showLoginModal, hideLoginModal }}>
      {children}
      {!isAuthenticated && (
        <>
          <Suspense fallback={<></>}>
            <LoginDialog openModal={openModal} hideLoginModal={hideLoginModal} />
          </Suspense>
        </>
      )}
    </LoginModalContext.Provider>
  );
};

export default LoginModalProvider;
