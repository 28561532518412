import { PaletteOptions } from '@mui/material';

const MuiPagination = (palette: PaletteOptions) => {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        '.MuiPaginationItem-root': {
          backgroundColor: 'rgba(100,100,100,0.5)',
          color: '#ff0000'
        },
        ul: {},
        outlined: {},
        '&.MuiPaginationItem-root': {
          color: 'green'
        },
        color: 'blue',
        root: {
          color: 'red'
        }
      },
      variants: [
        {
          props: { variant: 'determinate', color: 'secondary' },
          style: {
            height: 8,
            borderRadius: 5
          }
        }
      ]
    }
  };
};

export default MuiPagination;
