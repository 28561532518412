import React from 'react';
import type { ChildrenProp } from 'models/types/children-prop';
import LayoutBody from './components/LayoutBody';
import NavBar from './components/NavBar';
import ActionsBar from './components/ActionsBar';
import Footer from './components/Footer';
import Box from '@mui/material/Box';

const Dashboard: React.FC<ChildrenProp> = ({ children }) => {
  return (
    <LayoutBody>
      <NavBar />
      <ActionsBar />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        alignItems='center'
        mt={3}
        mb={6}
        position='relative'
      >
        {children}
      </Box>
      <Footer />
    </LayoutBody>
  );
};

export default Dashboard;
// export default React.memo(Dashboard)
