import React from 'react';
import LoginModalProvider from 'context/modals/LoginModalProvider/LoginModalProvider';
import PromocodeModalProvider from 'context/modals/PromocodeModalProvider/PromocodeModalProvider';
import AppPopups from './AppPopups';

type ModalsWrapperProps = {
  children: React.ReactNode;
};

const ModalsWrapper = ({ children }: ModalsWrapperProps) => {
  return (
    <>
      <LoginModalProvider>
        <PromocodeModalProvider>
          {children}
          <></>
        </PromocodeModalProvider>
      </LoginModalProvider>
      <AppPopups />
    </>
  );
};

export default ModalsWrapper;
