import { Theme } from '@mui/material';

export const getPaperStyleProps = (theme: Theme) => ({
  sx: {
    maxHeight: { phxs: '90vh', phlg: 600 },
    borderRadius: 4,
    overflow: 'auto',
    background: theme.palette.grey[800],
    color: theme.palette.white,
    width: 512,
    p: {
      phxs: 2,
      tbxs: 3
    },
    gap: 2
  }
});

export const getIconsButtonSXProps = (theme: Theme) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  m: 0.5,
  path: { fill: theme.palette.greyTransparency[100] }
});
