'use client';

import React from 'react';

import { Box, Dialog, IconButton, Typography, useTheme } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

import { NEXT_PUBLIC_RECAPTCHA_SITE_KEY } from '@config';

import CloseIcon from 'assets/new/MarketClose.svg';

interface RecaptchaPopupProps {
  open: boolean;
  onClose: () => void;
  onVerify: (token: string) => void;
}

const RecaptchaPopup: React.FC<RecaptchaPopupProps> = ({ open, onClose, onVerify }) => {
  const theme = useTheme();

  const handleChange = (recaptchaValue: string | null) => {
    if (recaptchaValue) {
      setTimeout(() => {
        onVerify(recaptchaValue);
        onClose();
      }, 1000);
    }
  };

  return (
    <Dialog
      key={'recaptcha-dialog'}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 440,
          minWidth: 320,
          borderRadius: 4,
          p: 3,
          background: theme.palette.grey[800]
        }
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={2}
      >
        <Typography variant='subtitle2' fontWeight={500}>
          Please Complete the Captcha
        </Typography>
        <ReCAPTCHA
          onChange={handleChange}
          theme='dark'
          sitekey={NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          //
        />
      </Box>

      {/* CLOSE BUTTON */}
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          m: 0.5,
          path: { fill: theme.palette.greyTransparency[100] }
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

export default RecaptchaPopup;
