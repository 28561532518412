import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FooterDetails: React.FC = () => {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-around'>
      <Typography
        variant='body1'
        align='center'
        fontWeight={300}
        color={(theme) => theme.palette.grey[300]}
      >
        The Website is operated by Mallet Solutions LP (Company registered in Northern Ireland)
        <br />
        Suite 425 142a Saintfield Road, Lisburn, Northern Ireland, BT27 6UH Phone: +442070975811.
        <br />
        Powered by Steam. Not affiliated with Valve Corp. You can open CS:GO cases on our website
        and get skins at the cheapest price.
        <br />© 2024 Drop.Skin All rights reserved
      </Typography>
    </Box>
  );
};

export default FooterDetails;
