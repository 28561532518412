import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { LastDrop } from 'models/types/stats-socket';

export type LastDropPayload = {
  payload: LastDrop[];
};

export type LastDropState = {
  lastDrop: LastDrop[];
};

export const INITIAL_STATE: LastDropState = {
  lastDrop: []
};

export const lastDropsReducer = createSlice({
  name: '@lastDrops',
  initialState: INITIAL_STATE,
  reducers: {
    setLastDrop(state, { payload }: LastDropPayload) {
      state.lastDrop = payload;
    },
    updateLastDrop(state, { payload }: LastDropPayload) {
      state.lastDrop.unshift(...payload);
      state.lastDrop.length = state.lastDrop.length - payload.length;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (action.payload.lastDrops.lastDrop) {
        return state;
      }
      state.lastDrop = action.payload.lastDrops.lastDrop;
    }
  }
});

export const { setLastDrop, updateLastDrop } = lastDropsReducer.actions;

export default lastDropsReducer.reducer;
