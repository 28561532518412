import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';


const INITIAL_STATE = {
  temp: '',
};

export const chatReducer = createSlice({
  name: '@chat',
  initialState: INITIAL_STATE,
  reducers: {
    setChat(state, { payload }) {
      state.temp = payload.temp;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      state.temp = action.payload.chat.temp;
    },
  },
});

export const { setChat } = chatReducer.actions;

export default chatReducer.reducer;
