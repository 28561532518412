import { PaletteOptions } from '@mui/material/styles';
import { pxToRem } from '../typography';

// ----------------------------------------------------------------------

export default function Input(palette: PaletteOptions) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: `2px solid ${palette.grey[600]}`,
          background: 'transparent',
          '&.Mui-disabled': {
            color: palette.white,
            WebkitTextFillColor: palette.white
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {}
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {},
        underline: {}
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // border: `2px solid ${palette.grey[600]}`
        },
        underline: {}
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        sizeSmall: {
          color: palette.white,
          fontSize: pxToRem(12),
          minHeight: 5,
          input: {
            minHeight: 5
          }
        },
        root: {
          fontSize: pxToRem(16),
          borderRadius: 8,
          border: `2px solid ${palette.grey[600]}`,
          backgroundColor: palette.grey[900],
          color: palette.white,
          minHeight: 6,
          paddingTop: 0,
          paddingBottom: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: palette.white
          },
          '&.Mui-error': {
            border: `1px solid ${palette.drop.red}`
          }
        },
        input: {
          fontSize: pxToRem(16),
          paddingLeft: 30,
          '&.Mui-disabled': {
            color: palette.white,
            WebkitTextFillColor: palette.white
          },
          '::placeholder': {
            color: palette.grey[400],
            opacity: 1
          },

          '::-ms-input-placeholder': {
            color: palette.grey[400]
          }
        }
      }
    }
  };
}
