import { Box, styled, Typography, useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import Button, { ButtonProps } from '@mui/material/Button';

import VpnIcon from 'assets/icon64/vpn.svg';
import MultiSessionIcon from 'assets/icon64/multi_session.svg';
import { useEffect, useState } from 'react';

export enum FraudAccessEnum {
  VPN = 'vpn',
  MULTISESSION = 'multisession',
  TWINK = 'twink'
}

interface IFraudAccessContentProps {
  fraud: FraudAccessEnum;
  fraudTime: number;
  onRefresh?: React.MouseEventHandler<HTMLButtonElement>;
}

export const FraudAccessContent: React.FC<IFraudAccessContentProps> = ({
  fraud,
  fraudTime,
  onRefresh
}) => {
  const data = getComponentData(fraud);
  if (!data) return null;

  const theme = useTheme();

  const ModalIcon = data.icon;

  return (
    <Box display='flex' gap={0.5} flexDirection='column' alignItems='center' textAlign='center'>
      <Box width={64} height={64} position='relative'>
        <ModalIcon />
      </Box>

      <Typography variant='h5' sx={{ mt: '10px' }}>
        Warning!
      </Typography>

      {fraudTime > 0 && (
        <Typography variant='h5' sx={{ mt: '10px' }}>
          <MillisecondsTimer milliseconds={fraudTime} />
        </Typography>
      )}

      <Typography variant='paragraph' sx={{ mt: '10px' }}>
        {data?.info}
      </Typography>

      <Typography variant='paragraph' sx={{ mt: '10px' }}>
        If you have any concerns or questions about our security policies, please contact our{' '}
        <Link href='/support' underline='hover' color={theme.palette.secondaries[400]}>
          support
        </Link>
      </Typography>

      <ModalButton variant='contained' onClick={onRefresh}>
        Refresh page
      </ModalButton>
    </Box>
  );
};

const ModalButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: 40,
  marginTop: 15,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grey[600],
  '&:hover': {
    backgroundColor: theme.palette.grey[900]
  },
  textTransform: 'none'
}));

const getComponentData = (key: FraudAccessEnum) => {
  const data = {
    [FraudAccessEnum.VPN]: {
      icon: VpnIcon,
      info: 'Our system detected that you are using VPN. Please turn it of to be able to enter our website If you have any concerns or questions about our security policies, please contact our support'
    },
    [FraudAccessEnum.MULTISESSION]: {
      icon: MultiSessionIcon,
      info: 'Our system detected that you opened the website in another window on your computer. Please close it to be able to enter our website If you have any concerns or questions about our security policies, please contact our support'
    },
    [FraudAccessEnum.TWINK]: {
      icon: MultiSessionIcon,
      info: 'Using multiple accounts or twinks is prohibited on our platform, as is having multiple accounts with the same IP address. We do this to ensure a fair and safe environment for all users. We may take action if we detect any rule violations, such as account suspension or termination. Thank you for cooperating with us in maintaining a positive community.'
    }
  };
  return data[key];
};

const MillisecondsTimer = ({ milliseconds }: { milliseconds: number }) => {
  
  const [time, setTime] = useState(Math.floor(milliseconds / 1000));

  const updateTime = () => {
    setTime((prev) => {
      if (prev > 0) {
        return --prev;
      }
      return 0;
    });
  };

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  return (
    <>
      {hours}h {minutes}m {seconds}s
    </>
  );
};
