import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import NotificationProvider from 'components/NotificationProvider';
import GlobalStyles from 'components/GlobalStyles';
import Notifications from 'components/Notifications';
import type { Page } from 'models/types/page';
import { AppProps } from 'next/app';
import { wrapper } from 'store';
import { createEmotionCache } from 'utils/createEmotionCache';
import { generateTheme } from '../theme';
import DashboardLayout from 'layouts/Dashboard';
import AuthProvider from 'components/AuthProvider';
import SocketConnection from 'components/SocketConnection';
import InternalScripts from 'components/InternalScripts';
import ModalsWrapper from 'components/ModalsWrapper';
import { FraudAccessModal } from 'components/Modals/FraudAccessModal';

// import '@fontsource/roboto';
import '@fontsource/kanit/300.css';
import '@fontsource/kanit/400.css';
import '@fontsource/kanit/500.css';
import '@fontsource/kanit/600.css';
import '@fontsource/kanit/700.css';
// import SpecialAlerts from 'components/SpecialAlerts';
import GlobalCodesChecker from 'components/RefCheckers/GlobalCodesChecker';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

type DROPprops = AppProps & {
  emotionCache: EmotionCache;
  Component: Page;
};

function DROPapp(props: DROPprops) {
  const theme = generateTheme('light');

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <NotificationProvider>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <GlobalCodesChecker />
            <ModalsWrapper>
              <GlobalStyles />
              <CssBaseline />
              <Notifications />
              <SocketConnection />
              <InternalScripts />
              <FraudAccessModal />
              <DashboardLayout>
                <Component {...pageProps} />
              </DashboardLayout>
              {/* <SpecialAlerts /> */}
            </ModalsWrapper>
          </AuthProvider>
        </ThemeProvider>
      </CacheProvider>
    </NotificationProvider>
  );
}

export default wrapper.withRedux(DROPapp);
