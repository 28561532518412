import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export interface AuthPayload {
  isLoading: boolean;
  isAuthenticated: boolean;
  token: string; // Token
}
export interface AuthKey {
  payload: AuthPayload;
}
export interface AuthState {
  isLoading: boolean;
  isAuthenticated: boolean;
  token: string;
}

const INITIAL_STATE: AuthState = {
  isLoading: true,
  isAuthenticated: false,
  token: ''
};

export const authReducer = createSlice({
  name: '@auth',
  initialState: INITIAL_STATE,
  reducers: {
    setAuth(state, { payload }: AuthKey) {
      state.isLoading = payload.isLoading;
      state.isAuthenticated = payload.isAuthenticated;
      state.token = payload.token;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state.isAuthenticated || state.token !== '') {
        return state;
      }
      state.isLoading = action.payload.auth.isLoading;
      state.isAuthenticated = action.payload.auth.isAuthenticated;
      state.token = action.payload.auth.token;
    }
  }
});

export const { setAuth } = authReducer.actions;

export default authReducer.reducer;
