import React from 'react';
import { StorePopupKeys } from 'store/slices/popups';
import dynamic from 'next/dynamic';

const PopupTrigger = dynamic(() => import('./Popups/PopupTrigger'), { ssr: false });

const KycPopup = dynamic(() => import('components/ModalsWrapper/Popups/KYC/KYCPopup'), {
  ssr: false
});
const KycVerificationPopup = dynamic(
  () => import('components/ModalsWrapper/Popups/KycVerification/KycVerificationPopup'),
  { ssr: false }
);

type PopupsType = {
  [key in StorePopupKeys]: React.ReactNode;
};

const POPUPS: PopupsType = {
  kycVerification: <KycVerificationPopup />,
  kyc: <KycPopup />
};

const AppPopups = () => {
  return (
    <>
      <PopupTrigger popupKey='kyc'>{POPUPS.kyc}</PopupTrigger>
      <PopupTrigger popupKey='kycVerification'>{POPUPS.kycVerification}</PopupTrigger>
    </>
  );
};

export default AppPopups;
