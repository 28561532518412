import { Typography, Button, Box } from '@mui/material';

import { useMarkAllAsReadMutation } from 'store/rtk/notifications/service';
import ReadIcon from 'assets/notifications/Read.svg';

const NotificationsHeader = ({ disableReadMark = false }) => {
  const [markAllAsRead, { isLoading }] = useMarkAllAsReadMutation();

  const handleReadAll = () => {
    markAllAsRead();
  };

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' height='auto' px={2}>
      <Typography variant='subtitle1'>Notifications</Typography>
      <Button
        disableRipple
        disabled={disableReadMark || isLoading}
        variant='text'
        size='small'
        startIcon={<ReadIcon />}
        onClick={handleReadAll}
      >
        Mark as read
      </Button>
    </Box>
  );
};

export default NotificationsHeader;
