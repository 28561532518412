import getConfig from 'next/config';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const { API_FOR_BUILD } = serverRuntimeConfig;

export const {
  ENV,
  API_URL,
  SITE_URL,
  ENABLE_AXIOS_LOGS,
  REACT_APP_API_HOST,
  SOCKET_API_URL,
  GOOGLE_STORAGE_URL,
  NEXT_PUBLIC_GOOGLE_ANALYTICS,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER,
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY
} = publicRuntimeConfig;
