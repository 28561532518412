import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StorePopupKeys = keyof typeof INITIAL_STATE;

interface IPopupPayload {
  key: StorePopupKeys;
  value?: boolean;
}

const INITIAL_STATE = {
  kyc: false,
  kycVerification: false
};

export const popupsReducer = createSlice({
  name: '@popups',
  initialState: INITIAL_STATE,
  reducers: {
    toggleKYC(state, action: PayloadAction<boolean>) {
      state.kyc = action.payload;
    },
    togglePopup(state, { payload: { key, value } }: PayloadAction<IPopupPayload>) {
      state[key] = value ?? !state[key];
    }
  }
});

export const { toggleKYC, togglePopup } = popupsReducer.actions;

export default popupsReducer.reducer;
