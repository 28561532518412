import tokenImg from 'assets/event/token/token.webp';
import HowToGetTokensImg from 'assets/event/how-to-get/how-to-get-tokens.webp';

// TABS
import HomeTabImg from 'assets/event/tabs/tab-home.webp';
import TokenBankTabImg from 'assets/event/tabs/tab-token-bank.webp';
import CardsTabImg from 'assets/event/tabs/tab-cards.webp';
import SkinGeneratorTabImg from 'assets/event/tabs/tab-skin-generator.webp';
import StoreTabImg from 'assets/event/tabs/tab-store.webp';

export const EVENT_COLOR = '#FFB872';

export const EVENT_TOKEN = {
  image: tokenImg
};

export const EVENT_TAB_HOW_TO_GET = {
  image: HowToGetTokensImg
};

export const EVENT_TABS = [
  {
    label: 'Home',
    name: 'home',
    link: '/event',
    img: HomeTabImg
  },
  {
    label: 'Token Bank',
    name: 'tokenBank',
    link: '/event/token-bank',
    img: TokenBankTabImg
  },
  {
    label: 'Cards',
    name: 'cards',
    link: '/event/cards',
    img: CardsTabImg
  },
  {
    label: 'Skin Generator',
    name: 'skinGenerator',
    link: '/event/skin-generator',
    img: SkinGeneratorTabImg
  },
  {
    label: 'Event Store',
    name: 'eventStore',
    link: '/event/store',
    img: StoreTabImg
  }
];
