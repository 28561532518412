import { alpha, PaletteOptions } from '@mui/material';
import spacing from '../utils/spacing';
import { pxToRem } from '../typography';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tertiary: true;
    link: true;
  }
}

const ButtonTypes = ['MuiButton', 'MuiLoadingButton'];

const MuiButton = (palette: PaletteOptions) => {
  return ButtonTypes.map((TYPE) => ({
    [TYPE]: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          color: palette.white,
          borderRadius: spacing(1),
          padding: '12px 16px',
          minWidth: spacing(10),
          height: spacing(6)
        }
      },
      variants: [
        // --------------- CONTAINED --------------- //
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: palette.primaries[400],
            color: palette.primaries[900],
            path: {
              fill: palette.primaries[900]
            },
            ':hover': {
              backgroundColor: palette.primaries[200]
            },
            '&.Mui-disabled': {
              backgroundColor: palette.grey[700],
              color: palette.grey[400],
              path: {
                fill: palette.grey[400]
              }
            }
          }
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: palette.secondaries[400],
            color: palette.secondaries[800],
            path: {
              fill: palette.secondaries[800]
            },
            ':hover': {
              backgroundColor: palette.secondaries[200]
            },
            '&.Mui-disabled': {
              backgroundColor: palette.grey[600],
              color: palette.grey[400],
              path: {
                fill: palette.grey[400]
              }
            }
          }
        },

        // --------------- OUTLINED --------------- //
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            border: `2px solid ${palette.primaries[400]}`,
            color: palette.primaries[400],
            path: {
              fill: palette.primaries[400]
            },
            ':hover': {
              border: `2px solid ${palette.primaries[800]}`,
              backgroundColor: palette.primaries[800]
            },
            '&.Mui-disabled': {
              border: `2px solid ${palette.grey[500]}`,
              color: palette.grey[500],
              path: {
                fill: palette.grey[500]
              }
            }
          }
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            border: `2px solid ${palette.secondaries[400]}`,
            color: palette.secondaries[400],
            path: {
              fill: palette.secondaries[400]
            },
            ':hover': {
              border: `2px solid ${palette.secondaries[800]}`,
              backgroundColor: palette.secondaries[800]
            },
            '&.Mui-disabled': {
              border: `2px solid ${palette.grey[500]}`,
              color: palette.grey[500],
              path: {
                fill: palette.grey[500]
              }
            }
          }
        },

        // --------------- CUSTOM --------------- //
        {
          props: { variant: 'tertiary', color: 'primary' },
          style: {
            backgroundColor: palette.grey[700],
            color: palette.primaries[400],
            path: {
              fill: palette.primaries[400]
            },
            ':hover': {
              backgroundColor: palette.primaries[400],
              color: palette.primaries[900],
              path: {
                fill: palette.primaries[900]
              }
            },
            '&.Mui-disabled': {
              backgroundColor: palette.grey[700],
              color: palette.grey[400],
              path: {
                fill: palette.grey[400]
              }
            }
          }
        },
        {
          props: { variant: 'tertiary', color: 'secondary' },
          style: {
            backgroundColor: palette.grey[600],
            color: palette.grey[100],
            path: {
              fill: palette.grey[100]
            },
            ':hover': {
              backgroundColor: palette.grey[400],
              color: palette.grey[900],
              path: {
                fill: palette.grey[900]
              }
            },
            '&.Mui-disabled': {
              backgroundColor: palette.grey[700],
              color: palette.grey[500],
              path: {
                fill: palette.grey[500]
              }
            }
          }
        },
        {
          props: { variant: 'link' },
          style: {
            textTransform: 'capitalize',
            color: palette.primaries[400],
            path: {
              fill: palette.primaries[400]
            },
            ':hover': {
              backgroundColor: palette.grey[600]
            },
            '&.Mui-disabled': {
              color: palette.grey[500],
              path: {
                fill: palette.grey[500]
              }
            }
          }
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            textTransform: 'capitalize',
            color: palette.primaries[400],
            path: {
              fill: palette.primaries[400]
            },
            ':hover': {
              backgroundColor: 'transparent',
              color: palette.primaries[200],
              path: {
                fill: palette.primaries[200]
              }
            },
            '&.Mui-disabled': {
              color: palette.grey[500],
              path: {
                fill: palette.grey[500]
              }
            }
          }
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            textTransform: 'capitalize',
            color: palette.secondaries[400],
            path: {
              fill: palette.secondaries[400]
            },
            ':hover': {
              backgroundColor: 'transparent',
              color: palette.secondaries[200],
              path: {
                fill: palette.secondaries[200]
              }
            },
            '&.Mui-disabled': {
              color: palette.grey[500],
              path: {
                fill: palette.grey[500]
              }
            }
          }
        },

        // --------------- SIZES --------------- //
        {
          props: { size: 'small' },
          style: {
            span: {
              marginRight: spacing(0.5)
            },
            fontSize: pxToRem(16),
            padding: spacing(1),
            height: spacing(5)
          }
        },
        {
          props: { size: 'large' },
          style: {
            fontSize: pxToRem(18),
            padding: '16px',
            height: spacing(7)
          }
        }
      ]
    }
  }));
};

export default MuiButton;
