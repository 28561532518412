import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

type StatInfoProps = {
  image: any;
  count: number;
  text: string;
  color: string;
};

const StatInfo = ({ image, count, text, color = '#fff' }: StatInfoProps) => {
  const theme = useTheme();

  return (
    <Box
      gap={1.5}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: {
          phxs: '50%',
          phlg: 'auto'
        },
        margin: {
          phxs: '20px 0',
          phlg: 'auto'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 64,
          height: 64,
          position: 'relative',
          svg: {
            width: {
              phxs: 48,
              lpxs: 64
            },
            height: {
              phxs: 48,
              lpxs: 64
            }
          },
          path: { fill: color }
          // '&:before': {
          //   content: '""',
          //   width: 64,
          //   height: 64,
          //   position: 'absolute',
          //   background: color,
          //   opacity: '0.2',
          //   margin: 'auto',
          //   borderRadius: '50%',
          //   filter: 'blur(60px)'
          // }
        }}
      >
        {image}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography
          variant='h4'
          color={color}
          fontSize={{ phxs: '24px !important', lpxs: '28px !important' }}
        >
          {count}
        </Typography>
        <Typography
          variant='subtitle1'
          fontSize={{ phxs: '16px !important', tbxs: '18px !important', lpxs: '20px !important' }}
          color={theme.palette.grey[300]}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(StatInfo);
