import CloseIcon from 'assets/notifications/types/Close.svg';
import CurrencyIcon from 'assets/notifications/types/Currency.svg';
import EventIcon from 'assets/notifications/types/Event.svg';
import GiftIcon from 'assets/notifications/types/Gift.svg';
import InfoIcon from 'assets/notifications/types/Info.svg';
import SuccessIcon from 'assets/notifications/types/Success.svg';
import DropLogoIcon from 'assets/notifications/types/DropLogo.svg';
import WarningIcon from 'assets/notifications/types/Warning.svg';

export const MESSAGE_TYPES = {
  BONUS: {
    icon: <CurrencyIcon />,
    color: '#39C6A6'
  },
  ERROR: {
    icon: <CloseIcon />,
    color: '#FF5254'
  },
  EVENT: {
    icon: <EventIcon />,
    color: '#F9D949'
  },
  GIVEAWAY: {
    icon: <GiftIcon />,
    color: '#FF5254'
  },
  INFO: {
    icon: <InfoIcon />,
    color: '#6498E1'
  },
  SUCCESS: {
    icon: <SuccessIcon />,
    color: '#39C6A6'
  },
  UPDATES: {
    icon: <DropLogoIcon />,
    color: '#FFB872'
  },
  WARNING: {
    icon: <WarningIcon />,
    color: '#FFB872'
  }
};

export enum NOTIFICATIONS_TABS {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}
