import { useEffect, useState } from 'react';
import { getCookie, deleteCookie } from 'cookies-next';
import { Dialog, IconButton, useTheme } from '@mui/material';

import CloseIcon from 'assets/new/MarketClose.svg';
import {
  FraudAccessEnum,
  FraudAccessContent,
  getPaperStyleProps,
  getIconsButtonSXProps,
  FRAUD_ACCESS_COOKIE,
  FRAUD_ACCESS_TIME_COOKIE
} from 'components/Modals/FraudAccessModal';

interface IFraudAccessModal {
  children?: React.ReactNode;
}

export const FraudAccessModal: React.FC<IFraudAccessModal> = () => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const cookie = getCookie(FRAUD_ACCESS_COOKIE) as FraudAccessEnum;
  const fraudTime = Number(getCookie(FRAUD_ACCESS_TIME_COOKIE));

  const theme = useTheme();

  useEffect(() => {
    if (isValidFraudAccessCookie()) setOpen(true);
  }, [cookie]);

  const handleClose = () => {
    setOpen(false);
    deleteCookie(FRAUD_ACCESS_COOKIE);
    deleteCookie(FRAUD_ACCESS_TIME_COOKIE);
  };

  const handleRefresh = () => {
    handleClose();
    location.reload();
  };

  const isValidFraudAccessCookie = () => {
    return cookie && Object.values(FraudAccessEnum).includes(cookie);
  };

  return (
    <Dialog
      key={'fraud-access-dialog'}
      open={isOpen}
      onClose={handleClose}
      PaperProps={getPaperStyleProps(theme)}
    >
      <IconButton onClick={handleClose} sx={getIconsButtonSXProps}>
        <CloseIcon />
      </IconButton>

      <FraudAccessContent fraudTime={fraudTime} fraud={cookie} onRefresh={handleRefresh} />
    </Dialog>
  );
};
