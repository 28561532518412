import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import NotificationItem from './NotificationItem';
import { NOTIFICATIONS_TABS } from 'constants/notifications-modal/notifications';

const StyledNotificationsList = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: 4
  },
  /* Track */
  '::-webkit-scrollbar-track': {
    background: theme.palette.greyTransparency[800]
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[700],
    borderRadius: 4
  }
}));

const NotificationsList = ({ list = [] }) => {
  return (
    <StyledNotificationsList>
      {list.map((item) => (
        <NotificationItem
          key={`notification-item-${item?.id}`}
          notificationId={item?.id}
          title={item?.title}
          message={item?.message}
          isRead={item?.isRead}
          messageType={item?.messageType}
          createdAt={item?.createdAt}
          isPrivate={item?.type === NOTIFICATIONS_TABS.PRIVATE}
        />
      ))}
    </StyledNotificationsList>
  );
};

export default NotificationsList;
