export interface IBreakpoints {
  phxs: number;
  ph: number;
  phlg: number;
  tbxs: number;
  tb: number;
  tblg: number;
  lpxs: number;
  lp: number;
  lplg: number;
  dtxs: number;
  dt: number;
  dtlg: number;
}

export const BREAKPOINTS: IBreakpoints = {
  phxs: 0,
  ph: 320,
  phlg: 480,
  tbxs: 640,
  tb: 768,
  tblg: 960,
  lpxs: 1024,
  lp: 1200,
  lplg: 1366,
  dtxs: 1440,
  dt: 1770,
  dtlg: 1920
};
