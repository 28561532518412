import { PaletteOptions } from '@mui/material/styles';
import {
  PaletteMode,
  MainColor,
  DropColor,
  IGiveaway,
  NotificationColor,
  alpha
} from '@mui/material';
import type { RarityColor } from 'models/types/rarity';

declare module '@mui/material' {
  interface MainColor {
    50?: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900?: string;
  }

  interface DropColor {
    green: string;
    red: string;
    orange: string;
    yellow: string;
  }

  interface GiveawayColor {
    '6:h': string;
    '1:d': string;
    '7:d': string;
    '30:d': string;
  }
  interface IGiveaway {
    card: GiveawayColor;
    modal: GiveawayColor;
    border: GiveawayColor;
    img: GiveawayColor;
  }

  interface NotificationColor {
    WARNING: string;
    INFO: string;
    ERROR: string;
    SUCCESS: string;
  }
}

const primaries: MainColor = {
  100: '#FFEDDC',
  200: '#FFDCB8',
  300: '#FFCA95',
  400: '#FFB872',
  500: '#B68657',
  600: '#6C533D',
  700: '#544234',
  800: '#3B312B',
  900: '#3B312B'
};

const secondaries: MainColor = {
  100: '#CEF1E9',
  200: '#87D7C5',
  300: '#52CDB1',
  400: '#39C6A6',
  500: '#32A48C',
  600: '#2B8371',
  700: '#246157',
  800: '#1D3F3C',
  900: '#1D3F3C'
};

const grey: MainColor = {
  50: '#F0F2F4',
  100: '#E2E5EA',
  200: '#CACCD7',
  300: '#A4A6BB',
  400: '#717293',
  500: '#53536F',
  600: '#363644',
  700: '#272730',
  800: '#1C1C22',
  900: '#17171C'
};

const greyTransparency: MainColor = {
  // 50: alpha('#FFFFFF', 0.7),
  100: alpha('#FFFFFF', 0.7),
  200: alpha('#E2E5EA', 0.7),
  300: alpha('#CBCDD6', 0.65),
  400: alpha('#A4A6BB', 0.6),
  500: alpha('#737491', 0.5),
  600: alpha('#54546E', 0.48),
  700: alpha('#363644', 0.6),
  800: alpha('#23232E', 0.8),
  900: alpha('#1B1B23', 0.9)
};

const white = '#fff';
const black = '#000';

const drop: DropColor = {
  green: '#39C6A6',
  red: '#FF5254', //'#D95751',
  orange: '#FFB872',
  yellow: '#ECD971'
};

const error = {
  contrastText: white,
  light: '#F1C0BE',
  main: '#FF5254', //'#D95751'
  dark: '#672C2E'
};

const rarity = {
  consumer_grade: '#E2E2E2',
  base_grade: '#E2E2E2',
  industrial_grade: '#40B3F3',
  'mil-spec': '#3B64F3',
  'mil-spec_grade': '#3B64F3',
  high_grade: '#3B64F3',
  restricted: '#7840DE',
  remarkable: '#7840DE',
  classified: '#D959E9',
  exotic: '#D959E9',
  covert: '#EB6969',
  extraordinary: '#EB6969',
  melee: '#ECD971',
  contraband: '#997606',
  active2: '#36BB9D',
  active3: '#FF5A5C',
  superior: '#D959E9',
  master: '#EB6969',
  distinguished: '#3B64F3',
  exceptional: '#7840DE',
  //----------NEW-----------
  default: '#E2E2E2',

  'Consumer Grade': '#E2E2E2',
  'Base Grade': '#E2E2E2',

  'Industrial Grade': '#40B3F3',

  'Mil-Spec': '#3B64F3',
  'Mil-Spec Grade': '#3B64F3',
  'High Grade': '#3B64F3',
  Distinguished: '#3B64F3',

  Restricted: '#7840DE',
  Remarkable: '#7840DE',
  Exceptional: '#7840DE',

  Classified: '#D959E9',
  Exotic: '#D959E9',
  Superior: '#D959E9',

  Covert: '#EB6969',
  Extraordinary: '#EB6969',
  Master: '#EB6969',

  Melee: '#ECD971',
  Contraband: '#997606',
  Active2: '#36BB9D',
  Active3: '#FF5A5C'
};

const rarityGradients = {
  default: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(254, 254, 254, 0.25) 100%)',
  gift: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(255, 184, 114, 0.25) 100%)',

  'Consumer Grade':
    'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(254, 254, 254, 0.25) 100%)',
  'Base Grade':
    'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(254, 254, 254, 0.25) 100%)',

  'Industrial Grade':
    'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(100, 152, 225, 0.25) 100%)',

  'Mil-Spec': 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(81, 104, 246, 0.25) 100%)',
  'Mil-Spec Grade':
    'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(81, 104, 246, 0.25) 100%)',
  'High Grade': 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(81, 104, 246, 0.25) 100%)',
  Distinguished:
    'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(81, 104, 246, 0.25) 100%)',

  Restricted: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(127, 74, 246, 0.25) 100%)',
  Remarkable: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(127, 74, 246, 0.25) 100%)',
  Exceptional: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(127, 74, 246, 0.25) 100%)',

  Classified: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(194, 61, 222, 0.25) 100%)',
  Exotic: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(194, 61, 222, 0.25) 100%)',
  Superior: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(194, 61, 222, 0.25) 100%)',

  Covert: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(217, 87, 81, 0.25) 100%)',
  Extraordinary: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(217, 87, 81, 0.25) 100%)',
  Master: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(217, 87, 81, 0.25) 100%)',

  Melee: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(249, 217, 73, 0.25) 100%)',
  Contraband: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(137, 107, 9, 0.25) 100%)',
  Active2: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(54, 187, 157, 0.25) 100%)',
  Active3: 'linear-gradient(180deg, rgba(30, 30, 41, 0.25) 0%, rgba(255, 90, 92, 0.25) 100%)'
};

const rarityLastDrop = {
  consumer_grade:
    'linear-gradient(0deg, rgba(226, 226, 226, 0.17) 0%, rgba(105, 105, 105, 0.05) 96.67%)',
  base_grade:
    'linear-gradient(0deg, rgba(226, 226, 226, 0.17) 0%, rgba(105, 105, 105, 0.05) 96.67%)',
  industrial_grade:
    'linear-gradient(0deg, rgba(64, 179, 243, 0.31) 0%, rgba(21, 48, 63, 0.13) 96.67%)',
  'mil-spec_grade':
    'linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(19, 27, 55, 0.28) 96.67%)',
  high_grade: 'linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(19, 27, 55, 0.28) 96.67%)',
  restricted: 'linear-gradient(0deg, rgba(118, 55, 234, 0.25) 0%, rgba(28, 15, 52, 0.17) 96.67%)',
  remarkable: 'linear-gradient(0deg, rgba(118, 55, 234, 0.25) 0%, rgba(28, 15, 52, 0.17) 96.67%)',
  classified: 'linear-gradient(0deg, rgba(217, 89, 233, 0.25) 0%, rgba(29, 12, 31, 0.1) 96.67%)',
  exotic: 'linear-gradient(0deg, rgba(217, 89, 233, 0.25) 0%, rgba(29, 12, 31, 0.1) 96.67%)',
  covert: 'linear-gradient(0deg, rgba(243, 92, 92, 0.25) 0%, rgba(105, 47, 47, 0.09) 96.67%)',
  extraordinary:
    'linear-gradient(0deg, rgba(243, 92, 92, 0.25) 0%, rgba(105, 47, 47, 0.09) 96.67%)',
  melee: 'linear-gradient(0deg, rgba(236, 217, 113, 0.25) 0%, rgba(36, 33, 18, 0.05) 96.67%)',
  contraband: 'linear-gradient(0deg, rgba(153, 118, 6, 0.25) 0%, rgba(36, 33, 18, 0.05) 96.67%)',
  superior: 'linear-gradient(0deg, rgba(217, 89, 233, 0.25) 0%, rgba(29, 12, 31, 0.1) 96.67%)',
  master: 'linear-gradient(0deg, rgba(243, 92, 92, 0.25) 0%, rgba(105, 47, 47, 0.09) 96.67%)',
  distinguished: 'linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(19, 27, 55, 0.28) 96.67%)',
  exceptional: 'linear-gradient(0deg, rgba(118, 55, 234, 0.25) 0%, rgba(28, 15, 52, 0.17) 96.67%)'
};

const rarityCaseItem = {
  consumer_grade:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(226, 226, 226, 0.1) 0%, rgba(226, 226, 226, 0) 50.19%)',
  base_grade:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(226, 226, 226, 0.1) 0%, rgba(226, 226, 226, 0) 50.19%)',
  industrial_grade:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(64, 179, 243, 0.2) 0%, rgba(64, 179, 243, 0) 50.19%)',
  'mil-spec_grade':
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(59, 100, 243, 0) 50.19%)',
  high_grade:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(59, 100, 243, 0) 50.19%)',
  restricted:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(120, 64, 222, 0.2) 0%, rgba(120, 64, 222, 0) 50.19%)',
  remarkable:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(120, 64, 222, 0.2) 0%, rgba(120, 64, 222, 0) 50.19%)',
  classified:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(217, 89, 233, 0.2) 0%, rgba(217, 89, 233, 0) 50.19%)',
  exotic:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(217, 89, 233, 0.2) 0%, rgba(217, 89, 233, 0) 50.19%)',
  covert:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(235, 105, 105, 0.2) 0%, rgba(235, 105, 105, 0) 50.19%)',
  extraordinary:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(235, 105, 105, 0.2) 0%, rgba(235, 105, 105, 0) 50.19%)',
  melee:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(236, 217, 113, 0.2) 0%, rgba(236, 217, 113, 0) 50.19%);',
  contraband:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(153, 118, 6, 0.2) 0%, rgba(153, 118, 6, 0) 50.19%)',
  active2:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(54, 187, 157, 0.2) 0%, rgba(54, 187, 157, 0) 50.19%)',
  superior:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(217, 89, 233, 0.2) 0%, rgba(217, 89, 233, 0) 50.19%)',
  master:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(235, 105, 105, 0.2) 0%, rgba(235, 105, 105, 0) 50.19%)',
  distinguished:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(59, 100, 243, 0) 50.19%)',
  exceptional:
    'linear-gradient(0deg, rgba(30, 33, 44, 0.5), rgba(30, 33, 44, 0.5)), linear-gradient(0deg, rgba(120, 64, 222, 0.2) 0%, rgba(120, 64, 222, 0) 50.19%)'
};

const rarityItem = {
  consumer_grade:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(226, 226, 226, 0.1) 0%, rgba(0, 0, 0, 0) 38.62%)',
  base_grade:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(226, 226, 226, 0.1) 0%, rgba(0, 0, 0, 0) 38.62%)',
  industrial_grade:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(64, 179, 243, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  'mil-spec':
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  'mil-spec_grade':
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  high_grade:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  restricted:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(120, 64, 222, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  remarkable:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(120, 64, 222, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  classified:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(217, 89, 233, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  exotic:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(217, 89, 233, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  covert:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(235, 105, 105, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  extraordinary:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(235, 105, 105, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  melee:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(236, 217, 113, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  contraband:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(153, 118, 6, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  active2:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(360deg, rgba(54, 187, 157, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  superior:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(217, 89, 233, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  master:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(235, 105, 105, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  distinguished:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)',
  exceptional:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(120, 64, 222, 0.2) 0%, rgba(0, 0, 0, 0) 38.62%)'
};
const rarityItemHotizontal = {
  consumer_grade:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(226, 226, 226, 0.1) 0%, rgba(0, 0, 0, 0) 48.62%)',
  base_grade:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(226, 226, 226, 0.1) 0%, rgba(0, 0, 0, 0) 48.62%)',
  industrial_grade:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(64, 179, 243, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  'mil-spec_grade':
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(59, 100, 243, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  high_grade:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(59, 100, 243, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  restricted:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(120, 64, 222, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  remarkable:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(120, 64, 222, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  classified:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(217, 89, 233, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  exotic:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(217, 89, 233, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  covert:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(235, 105, 105, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  extraordinary:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(235, 105, 105, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  melee:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(236, 217, 113, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  contraband:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(90deg, rgba(153, 118, 6, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  superior:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(217, 89, 233, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  master:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(235, 105, 105, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  distinguished:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(59, 100, 243, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)',
  exceptional:
    'linear-gradient(0deg, rgba(18, 19, 24, 0.7), rgba(18, 19, 24, 0.7)), linear-gradient(0deg, rgba(120, 64, 222, 0.2) 0%, rgba(0, 0, 0, 0) 48.62%)'
};

const giveaway: IGiveaway = {
  card: {
    '6:h': 'linear-gradient(90deg, #6F33F5 0%, #8C46FF 100%)',
    '1:d': 'linear-gradient(90deg, #4E87FA 0%, #47B2FF 100%)',
    '7:d': 'linear-gradient(90deg, #F04B4B 0%, #DF774B 100%)',
    '30:d': 'linear-gradient(90deg, #E88B43 0%, #F9CD49 100%)'
  },
  modal: {
    '6:h': 'linear-gradient(90deg, #8C46FF 0%, #6F33F5 100%)',
    '1:d': 'linear-gradient(90deg, #47B2FF 0%, #4E87FA 100%)',
    '7:d': 'linear-gradient(90deg, #DF774B 0%, #F04B4B 100%)',
    '30:d': 'linear-gradient(90deg, #F9CD49 0%, #E88B43 100%)'
  },
  border: {
    '6:h': '#8C46FF',
    '1:d': '#47B2FF',
    '7:d': '#E0784B',
    '30:d': '#F9CD49'
  },
  img: {
    '6:h': '#6F33F5',
    '1:d': '#4E87FA',
    '7:d': '#DD534A',
    '30:d': '#E89543'
  }
};

const notificationColors: NotificationColor = {
  WARNING: '#ECD971',
  INFO: '#3B64F3',
  ERROR: '#FF5A5C',
  SUCCESS: '#36BB9D'
};

declare module '@mui/material/styles' {
  interface Palette {
    primaries: MainColor;
    secondaries: MainColor;
    greyTransparency: MainColor;
    white: string;
    black: string;
    drop: DropColor;
    rarity: RarityColor;
    rarityCaseItem: RarityColor;
    rarityItem: RarityColor;
    rarityItemHotizontal: RarityColor;
    rarityLastDrop: RarityColor;
    simple: Palette['primary'];
    yellow: Palette['primary'];
    tertiary: Palette['primary'];
    link: Palette['primary'];
    giveaway: IGiveaway;
    rarityGradients: any;
    notificationColors: NotificationColor;
  }

  interface PaletteOptions {
    primaries?: MainColor;
    secondaries?: MainColor;
    greyTransparency?: MainColor;
    white?: string;
    black?: string;
    drop?: DropColor;
    rarity?: RarityColor;
    rarityCaseItem?: RarityColor;
    rarityItem?: RarityColor;
    rarityItemHotizontal?: RarityColor;
    rarityLastDrop?: RarityColor;
    simple: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    tertiary: PaletteOptions['primary'];
    link: PaletteOptions['primary'];
    giveaway: IGiveaway;
    rarityGradients: any;
    notificationColors: NotificationColor;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    primaries: MainColor;
    secondaries: MainColor;
    greyTransparency: MainColor;
    white: string;
    black: string;
    drop: DropColor;
    rarity: RarityColor;
    rarityCaseItem: RarityColor;
    rarityItem: RarityColor;
    rarityItemHotizontal: RarityColor;
    rarityLastDrop: RarityColor;
    simple: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    tertiary: PaletteOptions['primary'];
    link: PaletteOptions['primary'];
    giveaway: IGiveaway;
    rarityGradients: any;
    notificationColors: NotificationColor;
  }

  interface PaletteOptions {
    primaries: MainColor;
    secondaries: MainColor;
    greyTransparency: MainColor;
    white: string;
    black: string;
    drop?: DropColor;
    rarity?: RarityColor;
    rarityCaseItem?: RarityColor;
    rarityItem?: RarityColor;
    rarityItemHotizontal?: RarityColor;
    rarityLastDrop?: RarityColor;
    simple: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    tertiary: PaletteOptions['primary'];
    link: PaletteOptions['primary'];
    giveaway?: IGiveaway;
    rarityGradients?: any;
    notificationColors: NotificationColor;
  }
}

export const colors = {
  primaries,
  secondaries,
  white
};

export const createPalette = (mode: PaletteMode): PaletteOptions => ({
  mode,
  primary: {
    main: drop.orange
  },
  secondary: {
    main: drop.green,
    contrastText: white
  },
  simple: {
    main: grey[400],
    contrastText: white
  },
  yellow: {
    main: drop.yellow,
    contrastText: white
  },
  tertiary: {
    main: primaries[400],
    contrastText: white
  },
  link: {
    main: primaries[400],
    contrastText: white
  },
  primaries,
  secondaries,
  error,
  white,
  black,
  grey,
  greyTransparency,
  drop,
  rarity,
  rarityCaseItem,
  rarityItem,
  rarityItemHotizontal,
  rarityLastDrop,
  giveaway,
  rarityGradients,
  notificationColors,
  background: { default: grey[900] }
});
