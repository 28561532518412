import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useSetReferralMutation } from 'store/rtk/partnership/service';
import { notify } from 'store/slices/notifier';

const REF_CODE = 'ref';

const GlobalCodesChecker = () => {
  const { query, isReady } = useRouter();

  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const [setReferalMutation] = useSetReferralMutation();

  useEffect(() => {
    const check = () => {
      if (!isReady) return;

      const refCode = (query?.ref || localStorage.getItem(REF_CODE)) as string;

      if (!refCode) return;

      if (!isAuthenticated) {
        localStorage.setItem(REF_CODE, refCode);
        return;
      }

      setReferalMutation(refCode)
        .unwrap()
        .then(() => {
          dispatch(notify({ message: 'Referral successfully activated!', type: 'success' }));
        })
        .finally(() => {
          localStorage.removeItem(REF_CODE);
        });
    };

    check();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, query]);

  return <></>;
};

export default GlobalCodesChecker;
