import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Badge, IconButton, BadgeProps } from '@mui/material';

import { useAppSelector } from 'store';
import { useGetNotificationsQuery } from 'store/rtk/notifications/service';

import NotificationIcon from 'assets/header/Notification.svg';
import NotificationsMenu from './NotificationsMenu';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    padding: 4,
    borderRadius: '50%',
    top: 3,
    right: 4,
    border: `2px solid ${theme.palette.grey[800]}`
  }
}));

const UserNotifications = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const { data } = useGetNotificationsQuery(undefined, {
    skip: !isAuthenticated,
    pollingInterval: 5 * 60 * 1000
  });

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display='flex'>
      <IconButton onClick={handleMenu}>
        <StyledBadge
          color='secondary'
          variant={data?.notRead ? 'standard' : 'dot'}
          invisible={!data?.pubCount && !data?.notRead}
          badgeContent={data?.notRead || undefined}
          max={9}
        >
          <NotificationIcon />
        </StyledBadge>
      </IconButton>
      <NotificationsMenu
        notReadCount={data?.notRead}
        data={data?.notifications}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default UserNotifications;
