import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Logo from './Logo';
import { SOCKET_API_URL } from '@config';
import io from 'socket.io-client';
import { notify } from 'store/slices/notifier';
import { useAppDispatch } from 'store';
import { getCookie } from 'cookies-next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9998,
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

const sessionSocket = io(`${SOCKET_API_URL}/sessions`, {
  transports: ['websocket'],
  auth: {
    token: getCookie('accessToken')
  }
});

const socket = io(`${SOCKET_API_URL}`, {
  transports: ['websocket', 'pooling']
});

export default function SocketConnection() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    sessionSocket.on('connect', () => {
      setLoading(false);
      setError(false);
    });

    sessionSocket.on('disconnect', () => {
      setLoading(false);
      setError(true);
      sessionSocket.emit('disconected', sessionSocket.auth);
      sessionSocket.close();
    });

    socket.on('connect', () => {
      setLoading(false);
      setError(false);
    });

    socket.on('disconnect', () => {
      setLoading(false);
      setError(true);
      socket.close();
    });

    return () => {
      socket.close();
      sessionSocket.close();
    };
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(
        notify({
          message: 'Lost connection. Please refresh page!',
          type: 'warning'
        })
      );
    }
  }, [error]);

  // if (loading) {
  //   return (
  //     <RootStyle>
  //       <motion.div
  //         initial={{ rotateZ: 0 }}
  //         animate={{ rotateZ: 360 }}
  //         transition={{
  //           duration: 2,
  //           ease: 'easeInOut',
  //           repeatDelay: 1,
  //           repeat: Infinity
  //         }}
  //       >
  //         <Logo sx={{ width: '100%', height: '100%' }} />
  //       </motion.div>

  //       <Box
  //         component={motion.div}
  //         animate={{
  //           scale: [1.2, 1, 1, 1.2, 1.2],
  //           rotate: [270, 0, 0, 270, 270],
  //           opacity: [0.25, 1, 1, 1, 0.25],
  //           borderRadius: ['25%', '25%', '50%', '50%', '25%']
  //         }}
  //         transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
  //         sx={{
  //           width: 100,
  //           height: 100,
  //           borderRadius: '25%',
  //           position: 'absolute',
  //           border: (theme) => `solid 3px ${alpha(theme.palette.primary.main, 0.24)}`
  //         }}
  //       />

  //       <Box
  //         component={motion.div}
  //         animate={{
  //           scale: [1, 1.2, 1.2, 1, 1],
  //           rotate: [0, 270, 270, 0, 0],
  //           opacity: [1, 0.25, 0.25, 0.25, 1],
  //           borderRadius: ['25%', '25%', '50%', '50%', '25%']
  //         }}
  //         transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
  //         sx={{
  //           width: 200,
  //           height: 200,
  //           borderRadius: '25%',
  //           position: 'absolute',
  //           border: (theme) => `solid 8px ${alpha(theme.palette.secondary.main, 0.48)}`
  //         }}
  //       />
  //     </RootStyle>
  //   );
  // }

  return null;
}
