import { Menu, useTheme } from '@mui/material';

import NotificationsBody from './NotificationsBody';

const NotificationsMenu = ({ notReadCount = 0, data = [], anchorEl, open, handleClose }) => {
  const theme = useTheme();

  return (
    <Menu
      id='notifications-menu'
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          background: theme.palette.greyTransparency[800],
          backdropFilter: 'blur(24px)',
          border: `1px solid ${theme.palette.greyTransparency[500]}`,
          borderRadius: 4,
          mt: 3,
          width: {
            phxs: '80vw',
            phlg: 400
          },
          height: {
            phxs: '85vh',
            phlg: 600
          },
          pt: 1,
          pb: 2
        }
      }}
      MenuListProps={{
        sx: {
          p: 0,
          height: '100%',
          overflow: 'hidden'
        }
      }}
    >
      <NotificationsBody list={data} notReadCount={notReadCount} />
    </Menu>
  );
};

export default NotificationsMenu;
